<template>
  <div class="listoperators">
    <ModalEditOperator
        v-if="modalOpened"
        :editPermission="editPermission"
        :operator="currentOperator"
        @close-modal="closeModalOperator"
    />
    <ModalSendSMS
        v-if="modalSendSMSOpened"
        @close-modal="closeModalSendSMS"
    />

    <div class="page-header">
      <h2 class="page-header__title">
        {{ $t('PhonesText.header') }}
      </h2>
      <div class="buttons-block">
        <div
            v-if="operatorsScreenOpened"
             class="page-header__outload"
             @click="downloadList('xls')">
          <img
              width="20" height="16"
              src="@/assets/img/bi_cloud_download.svg"
              alt="notification avatar">
          <p class="page-header__outload-text">
            {{ $t('Buttons.download_xls') }}
          </p>
        </div>

        <div v-if="operatorsScreenOpened"
             class="page-header__newcode"
             @click="openModalOperatorInformation(null)"
        >
          <p class="page-header__newcode-text">
            {{ $t('PhonesText.add_operator') }}
          </p>
        </div>

        <div v-if="statisticsScreenOpened"
             class="page-header__newcode"
             @click="openModalSendSMS()"
        >
          <p class="page-header__newcode-text">
            {{ $t('PhonesText.send_sms') }}
          </p>
        </div>

      </div>
    </div>

    <div class="buttons-panel">

      <div class="buttons-panel__operators"
        @click="operatorsScreenOpen()"
        :class="{ active: operatorsScreenOpened}"
      >
        <p class="buttons-panel__operators-text">
          {{ $t('PhonesText.operators') }}
        </p>
        <div class="buttons-panel__operators-counter">
          <p>
            {{ operatorsCount }}
          </p>
        </div>
      </div>

      <div class="buttons-panel__operators"
        @click="statisticsScreenOpen()"
        :class="{ active: statisticsScreenOpened}"
      >
        <p class="buttons-panel__operators-text">
          {{ $t('PhonesText.statistics') }}
        </p>
      </div>

      <div class="buttons-panel__input">
        <p class="buttons-panel__input-text">
          {{ $t('InterfaceText.search') }}
        </p>
        <input type="text" class="buttons-panel__input-input" :placeholder="$t('FieldsText.enter_request')"
               :value="filterData.search"
               @input="setListsSearch">
        <div class="buttons-panel__input-button"></div>
      </div>

      <div class="buttons-panel__input select">
      </div>

    </div>

    <ListsOperatorsModal
      v-if="operatorsScreenOpened"
      :editPermission="editPermission"
      :search="filterData.search"
      @refresh="getOperators"
      @edit="openModalOperatorInformation"/>

    <StatisticsOperatorModal
      v-if="statisticsScreenOpened"
      :editPermission="editPermission"
      :search="filterData.search"
      @refresh="getOperatorStatistics"/>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import InputsMixin from "@/mixins/inputsEvent";

import ModalEditOperator from "@/components/ModalEditOperator";
import ModalSendSMS from "@/components/ModalSendSMS";

import ListsOperatorsModal from '../components/ListsOperatorsModal.vue'
import StatisticsOperatorModal from '../components/StatisticsOperatorModal.vue'

import {hasContent} from "@/tools/dataTools";
import {UsersType} from "@/models/users";

export default {
  name: 'ListItems',
  components: {
    ModalEditOperator,
    ListsOperatorsModal,
    StatisticsOperatorModal,
    ModalSendSMS,
  },
  mixins: [InputsMixin],
  data() {
    return {
      isLoading: false,
      modalOpened: false,
      modalSendSMSOpened: false,

      currentOperator: null,

      operatorsScreenOpened: true,
      statisticsScreenOpened: false,

      // data
      filterData: {
        search: '',
        operator: null,
      },
    }
  },
  beforeMount() {
  },
  mounted() {
    if (this.user?.token) {
      this.filterData = {
        search: this.list_phones_filter.search,
      }

      this.getOperators()

      this.operatorsScreenOpen()
    }
    else {
      this.$router.push({ name: "Login" }).catch(() => {});
    }
  },
  computed: {
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN) : false;
    },
    editPermission() {
      return this.isAdmin;
    },
    operatorsCount() {
      return this.GET_LIST_PHONES().operators ? this.GET_LIST_PHONES().operators.length : 0;
    },
    list_phones_filter() {
      return this.GET_LIST_PHONES().filter;
    },
    employees_filter() {
      return this.GET_EMPLOYEES().filter;
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    "$store.state.employees.filter": function (newValue, oldValue) {
      if(this.user) {
        if(newValue != null) {
          this.filterData.company = newValue.company
        }

        this.filterItems();
      }
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.list_phones.filter": function (newValue, oldValue) {
      if(newValue != null) {
        this.filterData.search = newValue.search
      }

      if(newValue.search !== oldValue.search)
        this.filterItems();
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.list_phones.operators": function (newValue, oldValue) {
      this.filterItems();
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  methods: {
    ...mapGetters(["GET_USER","GET_LIST_PHONES","GET_EMPLOYEES",]),
    ...mapActions([
      "SET_LIST_ITEMS_FILTER",
      "GET_OPERATORS",
      "GET_OPERATORS_DOWNLOAD",
      "GET_DETERMINING",
      "GET_DETERMINING_DOWNLOAD",
    ]),

    operatorsScreenOpen() {
      this.filterData.search = '';
      this.operatorsScreenOpened = true;
      this.statisticsScreenOpened = false;
    },
    statisticsScreenOpen() {
      this.filterData.search = '';
      this.operatorsScreenOpened = false;
      this.statisticsScreenOpened = true;
    },

    setListsSearch(e) {
      if(hasContent(e.target.value)) {
        this.filterData.search = e.target.value
        this.filterItems()
      }

      this.SET_LIST_ITEMS_FILTER({
        ...this.list_phones_filter,
        search: e.target.value,
      });
    },

    filterItems() {

    },


    openModalOperatorInformation(operator) {
      this.currentOperator = operator;
      this.modalOpened = true;
    },

    closeModalOperator() {
      this.currentOperator = null;
      this.modalOpened = false;

      this.getOperators()
    },


    openModalSendSMS() {
      this.modalSendSMSOpened = true;
    },

    closeModalSendSMS() {
      this.modalSendSMSOpened = false;
    },


    getOperators() {
      if(this.user) {
        this.isLoading = true

        this.GET_OPERATORS({
          search: this.filterData.search,
        }).then(() => {
          this.isLoading = false
        });
      }
    },

    getOperatorStatistics() {
      if(this.user) {
        this.isLoading = true

        this.GET_DETERMINING({
          search: this.filterData.search,
        }).then(() => {
          this.isLoading = false
        });
      }
    },

    downloadList(format) {
      if(this.operatorsScreenOpened)
        this.downloadOperatorsList(format)
      else if(this.statisticsScreenOpened)
        this.downloadDeterminingList(format)
    },

    // requests
    downloadOperatorsList(format) {
      if (format) {
        this.isLoading = true

        // download specific format
        this.GET_OPERATORS_DOWNLOAD({
          format,
        }).then(() => {
          this.isLoading = false

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('Errors.file_is_generated'),
          });
        }).catch(() => {
          this.isLoading = false

          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.file_is_not_generated'),
          });
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

    // requests
    downloadDeterminingList(format) {
      if (format) {
        this.isLoading = true

        // download specific format
        this.GET_DETERMINING_DOWNLOAD({
          format,
        }).then(() => {
          this.isLoading = false

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('Errors.file_is_generated'),
          });
        }).catch(() => {
          this.isLoading = false

          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.file_is_not_generated'),
          });
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

    setFilterOperator(data) {
      console.log('setFilterOperator')
      console.log(data)
    }

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.listoperators {
  flex: 1;
  width: 100%;
  min-width: 1150px;

  .buttons-panel {
    margin-bottom: 30px;
  }

  .buttons-panel__checkbox {
    margin-right: 30px;
  }

  .table {
    //@include grid-table-row(60px, 30px, 11% 88px 11% 6px 11% 31px 7.3% 43px 13.5% 18px 10% 23px 10% 69px 3.4%);
    @include grid-table-row(145px, 60px, 11.8% 136px 13.75% 136px 14% 153px 8.2% 125px 6%);

    &__cell {
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 4;
      }
      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 6;
      }
      &:nth-child(4) {
        grid-column-start: 7;
        grid-column-end: 8;
      }
      &:nth-child(5) {
        grid-column-start: 9;
        grid-column-end: 10;
      }
      &:nth-child(6) {
        grid-column-start: 11;
        grid-column-end: 12;
      }
      &:nth-child(7) {
        grid-column-start: 13;
        grid-column-end: 14;
      }
      &:nth-child(8) {
        grid-column-start: 15;
        grid-column-end: 16;
      }
    }
  }

  .table2 {
    @include grid-table-row(60px, 40px, 27% 207px 25.2% 210px 6%);

    &__cell {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 2;
      }
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 4;
      }
      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 6;
      }
    }
  }

}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  &__title {
    @include page-header;
  }
}

.buttons-block {
  display: flex;
}

.page-header__outload {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 180px;
  padding: 7px 0;
  background-color: $white;
  border-radius: 5px;
  border: 1px solid $purple;
  cursor: pointer;
  // border-image: linear-gradient(#8F21C9, #4640B9) 30 / 1 / 0 stretch;
  svg {
    margin-right: 5px;
  }
  img {
   margin-right: 5px;
  }
  &-text {
    font-size: 14px;
    font-weight: 16px;
    color: $purple;
  }
}

.buttons-panel {
  display: flex;
  align-items: center;
  padding: 25px 110px 25px 30px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 2px #CED5F480;
}

.buttons-panel__operators {
  display: flex;
  align-items: center;
  margin-right: 25px;
  padding-right: 26px;
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: -4.5px;
    right: 0;
    width: 2px;
    height: 37px;
    background-color: $bodyBackground;
  }

  &-text {
    font-size: 16px;
    font-weight: 400;
    margin-right: 7px;
  }

  &-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    min-width: 28px;
    height: 28px;
    min-height: 28px;
    background: $inputCaption;
    border-radius: 50px;
    p {
      color: $white;
    }
  }

  &.active {
    .buttons-panel__operators-text {
      font-weight: 600;
    }

    .buttons-panel__operators-counter {
      background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
    }
  }
}

.buttons-panel__questions {
  display: flex;
  align-items: center;
  margin: 0 auto 0 0;
  cursor: pointer;
  &-text {
    font-size: 16px;
    font-weight: 400;
    margin-right: 7px;
  }

  &-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    min-width: 28px;
    height: 28px;
    min-height: 28px;
    background: $inputCaption;
    border-radius: 50px;
    p {
      color: $white;
    }
  }

  &.active {
    .buttons-panel__questions-text {
      font-weight: 600;
    }

    .buttons-panel__questions-counter {
      background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
    }
  }
}

.buttons-panel__input {
  display: flex;
  align-items: center;
  margin-right: 30px;
  &-text {
    font-size: 12px;
    font-weight: 500;
    margin-right: 30px;
  }

  &-input {
    @include search-input;
  }

  &-button {
    @include search-input__button;
  }

  &.select {
    .buttons-panel__input-text {
      position: relative;
      &::after {
        content: "*";
        position: absolute;
        top: -2px;
        right: -6px;
        color: $red;
      }
    }

    .buttons-panel__input-button {
      &::after {
        width: 9px;
        height: 7px;
        background: url('../assets/img/triangle.png');
      }
    }
  }
}

.buttons-panel__checkbox {
  display: flex;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  &-checkbox {
    display: none;
  }
  &-fake {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 12px;
    border-radius: 2px;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    position: relative;
    &::before {
      content: '✔';
      position: absolute;
      color: $black;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }
  &-text {
    font-size: 12px;
    font-weight: 500;
    color: $activeText;
  }
  .buttons-panel__checkbox-checkbox:checked + .buttons-panel__checkbox-fake::before {
    opacity: 1;
  }
  span {
    color: $red;
  }
}

</style>
