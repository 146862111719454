<template>
  <div class="handbooks">
    <div class="page-header">
      <h2 class="page-header__title">
        {{ $t('NavigationDrawerText.nav_tips') }}
      </h2>
      <div class="buttons-block">

        <div
          v-if="createPermission"
          class="page-header__newHandbook"
          @click="addNewHandbookModalOpen()"
        >
          <p class="page-header__requisitesDownload-text">
            {{ $t('TipsText.new_tip_button') }}
          </p>
        </div>

      </div>
    </div>

    <div class="handbooks__inner" >
      <div
        class="handbooks__item"
        v-for="tip in tips"
        :key="tip.id"
      >
        <img class="handbooks__item-icon" src="@/assets/img/handbooks-item-icon.png" alt="handbooks item icon">
        <p class="handbooks__item-text" @click="viewHandbookItem(tip)">{{ tip.name }}</p>
        <div v-if="isAdmin" class="handbooks__item-button">
          <img
              class="handbooks__item-img"
              src="@/assets/img/handbooks-button-img.png"
              @click="editHandbookItem(tip)"
              alt="handbooks button img">
        </div>
      </div>
    </div>

    <br>

    <div
        v-if="editItem"
        class="handbooks__content">

      <div class="buttons-block">
      <p class="buttons-block__validation-rule long">
        <input
            class="new-handbook__item-input"
            style="width:300px;"
            type="text"
            v-model="editorName"
            :placeholder="$t('TipsText.enter_tip_name')">
      </p>
      <div class="delete-button" :title="$t('Buttons.delete')" @click="deleteHandbookItem(selectedItem)">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.0001 3.25383e-08C10.8855 -0.000115151 11.7389 0.305579 12.3928 0.857061C13.0467 1.40854 13.4539 2.16597 13.5344 2.98063H19.0325C19.2777 2.9807 19.5138 3.06676 19.6929 3.22143C19.8721 3.3761 19.981 3.58784 19.9977 3.81387C20.0145 4.03989 19.9377 4.26336 19.783 4.4391C19.6282 4.61485 19.4071 4.72977 19.1642 4.76066L19.0325 4.769H18.2803L16.6454 17.8599C16.5726 18.441 16.2711 18.9771 15.7984 19.3662C15.3257 19.7553 14.7146 19.9703 14.0815 19.9702H5.9188C5.28569 19.9703 4.67464 19.7553 4.20189 19.3662C3.72915 18.9771 3.4277 18.441 3.35489 17.8599L1.71874 4.769H0.967756C0.733898 4.76899 0.507953 4.69074 0.331707 4.54871C0.155461 4.40669 0.0408371 4.2105 0.00903235 3.99642L0 3.87481C9.86195e-06 3.65873 0.0847014 3.44997 0.238413 3.28712C0.392124 3.12427 0.604456 3.01836 0.836141 2.98897L0.967756 2.98063H6.4659C6.54639 2.16597 6.95356 1.40854 7.60747 0.857061C8.26139 0.305579 9.1148 -0.000115151 10.0001 3.25383e-08ZM8.06464 7.45156C7.7485 7.45156 7.48398 7.63636 7.42979 7.87958L7.41946 7.97377V15.2763L7.42979 15.3693C7.48398 15.6125 7.7485 15.7973 8.06464 15.7973C8.38077 15.7973 8.64529 15.6125 8.69948 15.3693L8.70981 15.2751V7.97496L8.69948 7.87958C8.64529 7.63756 8.38077 7.45156 8.06464 7.45156ZM11.9357 7.45156C11.6195 7.45156 11.355 7.63636 11.3008 7.87958L11.2905 7.97377V15.2763L11.3008 15.3693C11.355 15.6125 11.6195 15.7973 11.9357 15.7973C12.2518 15.7973 12.5163 15.6125 12.5705 15.3693L12.5808 15.2751V7.97496L12.5705 7.87958C12.5163 7.63756 12.2518 7.45276 11.9357 7.45276V7.45156ZM10.0001 1.78838C9.21949 1.78838 8.56787 2.30104 8.41948 2.98063H11.5808C11.4311 2.30104 10.7808 1.78838 10.0001 1.78838Z" fill="#BDBDBD"/>
        </svg>
      </div>
      <div
          class="buttons-block__back"
          @click="viewHandbookItem(selectedItem)"
      >
        <p class="buttons-block__back-text">
          {{ $t('Buttons.cancel') }}
        </p>
      </div>
      <div
          class="buttons-block__forward"
          @click="saveHandbookItem"
      >
        <p class="buttons-block__forward-text">
          {{ $t('Buttons.save') }}
        </p>
      </div>
    </div>

      <VueEditor
          style="width: 100%; margin-top: 10px;"
          ref="vEditor"
          v-model="editorContent"
          use-custom-image-handler
          use-markdown-shortcuts
          prepend-links-https
          @focus="onEditorFocus"
          @blur="onEditorBlur"
          @imageAdded="handleImageAdded"
          @image-removed="handleImageRemoved"
      />
    </div>

    <br>

    <div ref="tipView" id="tipView" v-if="selectedItem && !editItem" class="handbooks__content bottom">
      <VueEditor
          style="width: 100%; margin-top: 10px;"
          ref="vEditorView"
          v-model="selectedItem.description"
          prepend-links-https
          :disabled="true"
          :editorToolbar="[{}]"
          :editorOptions="[{}]"
      />
      <!--div
          class="html_viewer margin_content"
          id="result"
          v-html="selectedItem.description"
      ></div-->
    </div>

    <AddNewHandbookModal
      v-if="addNewHandbookModalOpened"
      @close-modal="addNewHandbookModalClose()"
      @add="addNewHandbookItem"
    />
  </div>
</template>

<script>
import { baseURL } from "@/common/config.js";
import {mapActions, mapGetters} from "vuex";

import Quill from "quill";
const AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);

const BlockEmbed = Quill.import("blots/block/embed");

import AddNewHandbookModal from '../components/AddNewHandbookModal.vue'

import {UsersType} from "@/models/users";

/**
 * Customize image so we can add an `id` attribute
 */
class ImageBlot extends BlockEmbed {
  static create(value) {
    const node = super.create();
    node.setAttribute("src", value.url);
    node.setAttribute("id", value.id);
    return node;
  }

  static value(node) {
    return {
      url: node.getAttribute("src"),
      id: node.getAttribute("id")
    };
  }
}

ImageBlot.blotName = "image";
ImageBlot.tagName = "img";
Quill.register(ImageBlot);

import {
  NewTips,
  TipsFromBody,
} from "@/models/tips";

import {hasContent, isEmpty} from "@/tools/dataTools";

export default {
  name: 'Handbooks',
  components: {
    AddNewHandbookModal,
  },
  data() {
    return {
      addNewHandbookModalOpened: false,

      isLoading: false,

      editItem: false,
      selectedItem: null,

      editorName: '',
      editorContent: '',

    }
  },
  watch: {
    editorContent() {
      //console.log(this.editorContent)
    },

    "$store.state.tips": function () {
      if(hasContent(this.selectedItem) && hasContent(this.selectedItem.id)) {
        let item = this.tips.find((d) => d.id === this.selectedItem.id)

        if(hasContent(item))
          this.viewHandbookItem(item)
      }
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  computed: {
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN && this.user.isAdmin) : false;
    },
    createPermission() {
      return this.isAdmin;
    },
    tips() {
      return this.GET_TIPS();
    }
  },
  mounted() {
    if (this.user?.token) {
      this.getAllTipsAction()
    }
    else {
      this.$router.push({ name: "Login" }).catch(() => {});
    }
  },
  methods: {
    ...mapGetters(["GET_USER", "GET_TIPS",]),
    ...mapActions(["CREATE_TIP","UPDATE_TIP","DELETE_TIP","UPLOAD_TIP_IMAGE","GET_TIPS_FROM","GET_TIP_FROM",]),

    NewTips,
    TipsFromBody,

    scrollToTipView() {
      this.$nextTick(() => {
        if (document.getElementById('tipView')) {
          this.$nextTick(() => {
            document.getElementById('tipView').scrollIntoView({ behavior: 'smooth', block: 'start' })
          })
        }
      })
    },

    addNewHandbookModalOpen() {
      this.addNewHandbookModalOpened = true
    },

    addNewHandbookModalClose() {
      this.addNewHandbookModalOpened = false
    },

    addNewHandbookItem(name) {
      if (hasContent(name)) {
        this.selectedItem = NewTips()

        this.editorName = name
        this.editorContent = `<h1 style="text-align: center;">${name}</h1><p style="text-align: center;"><br></p><p style="text-align: justify;"><br></p>`

        this.editItem = true
      }
    },

    viewHandbookItem(item) {
      //this.editItem = false

      this.getTipAction(item, false)

      //this.selectedItem = item

      //console.log(this.$refs.vEditor)
      //this.$refs.vEditor.quill.enable(false)
    },

    saveHandbookItem() {
      // check if name exists
      if(this.selectedItem && hasContent(this.editorName)) {

        if(isEmpty(this.selectedItem.id))
          this.createTip(this.selectedItem)
        else
          this.editTip(this.selectedItem)
      }
    },

    editHandbookItem(item) {
      this.getTipAction(item, true)
      // this.selectedItem = item
      //
      // this.editorName = item.name
      // this.editorContent = item.description
      //
      // this.editItem = true

      //this.$refs.vEditor.quill.enable(true)
    },

    getAllTipsAction() {
      this.isLoading = true

      this.GET_TIPS_FROM().then((res) => {
        this.isLoading = false

        if (res.success) {
          // data was received
        } else if (res.error === "errorDBRequest" && res.message != null &&
            res.message.name === "SequelizeUniqueConstraintError") {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('TipsText.error_db'),
          });
        } else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_try_again'),
          });
        }
      });
    },

    getTipAction(item, edit) {
      this.isLoading = true
      this.editItem = false

      this.GET_TIP_FROM({
        id: item ? item.id : null
      }).then((res) => {
        this.isLoading = false

        if (res.success) {
          // data was received
          this.selectedItem = res.tip

          this.editItem = edit

          if(edit) {
            this.editorName = this.selectedItem.name
            this.editorContent = this.selectedItem.description
          }

          this.scrollToTipView()
        } else if (res.error === "errorDBRequest" && res.message != null &&
            res.message.name === "SequelizeUniqueConstraintError") {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('TipsText.error_db'),
          });
        } else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_try_again'),
          });
        }
      });
    },

    createTip(item) {
      if(item) {
        this.isLoading = true

        this.CREATE_TIP({
          id: item.id,
          name: this.editorName,
          description: this.editorContent,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {

            if(res.tip)
              this.viewHandbookItem(res.tip)

            this.editItem = false

            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('TipsText.tip_created'),
            });
          } else if (res.error === "errorDBRequest" && res.message != null &&
              res.message.name === "SequelizeUniqueConstraintError") {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: this.$t('TipsText.error_db'),
            });
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: this.$t('Errors.error_try_again'),
            });
          }
        });

      }
    },

    editTip(item) {
      if(item) {
        this.isLoading = true

        this.UPDATE_TIP({
          id: item.id,
          name: this.editorName,
          description: this.editorContent,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {

            if(res.tip)
              this.viewHandbookItem(res.tip)

            this.editItem = false

            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('TipsText.tip_updated'),
            });
          } else if (res.error === "badModelData" && res.message != null &&
              res.message.name === "SequelizeUniqueConstraintError") {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: this.$t('TipsText.error_db'),
            });
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: this.$t('Errors.error_try_again'),
            });
          }
        });
      }
    },

    deleteHandbookItem(item) {
      if (!item)
        return

      if (
          confirm(this.$t('TipsText.delete'))
      ) {
        this.isLoading = true

        this.DELETE_TIP({
          id: item.id,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {

            this.selectedItem = null
            this.editItem = false

            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('TipsText.delete_success'),
            });
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.not_success_operation'),
              text: this.$t('TipsText.delete_tip_error'),
            });
          }
        });
      }
    },

    handleTextChange(obj) {
      console.log("TCL: handleTextChange -> obj", obj);
    },

    // eslint-disable-next-line no-unused-vars
    onEditorBlur(quill) {
      //console.log("editor blur!", quill);
    },

    // eslint-disable-next-line no-unused-vars
    onEditorFocus(quill) {
      //console.log("editor focus!", quill);
    },

    async handleImageAdded(file, Editor, cursorLocation) {
      this.isLoading = true

      let formData = new FormData();

      formData.append("scan", file);
      formData.append("id", this.selectedItem.id);

      this.UPLOAD_TIP_IMAGE( {
        id: this.selectedItem.id,
        scan: formData,
      }).then((res) => {
        this.isLoading = false

        if (res.success) {
          console.log(res)

          const { url, id } = res;
          Editor.insertEmbed(
              cursorLocation,
              "image",
              {
                id,
                url: `${baseURL}${url}`
              },
              Quill.sources.USER
          );

        } else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_try_again'),
          });
        }
      });

    },

    handleImageRemoved(image) {
      console.log("handleImageRemoved -> image");

      if(image) {
        console.log(image)
      }
    }

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.handbooks {
  flex: 1;
  width: 100%;
  min-width: 1150px;

  &__inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 43px;
    padding: 35px 170px 40px 40px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 2px rgba($shadow, 0.5);
  }

  &__content {
    padding: 15px 15px 15px 15px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 2px rgba($shadow, 0.5);

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 90%;
      height: auto;
    }
  }

  .page-header__newHandbook {
    @include purple-button;
    width: 200px;
    color: $white;
  }
}

.margin_content {
  margin-left: 25px;
  margin-right: 25px;
}

.bottom {
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.page-header {
  &__newcompany {
    @include purple-button;
    width: 170px;
  }
}


.handbooks__item {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  border: 1px solid $purple;
  border-radius: 10px;
  padding: 11px 10px 12px 13px;
  &-icon {
    width: 47px;
    height: 47px;
    margin-right: 15px;
  }
  &-text {
    padding-right: 11px;
    color: $purple;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 14px;
    position: relative;
    cursor: pointer;
    margin-left: auto;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: -10px;
      width: 2px;
      height: 40px;
      background: $purple;
    }
  }

  &-img {

  }
}

.example {
  display: flex;
  flex-direction: column;

  .editor {
    height: 40rem;
    overflow: hidden;
  }

  .output {
    width: 100%;
    height: 20rem;
    margin: 0;
    border: 1px solid #ccc;
    overflow-y: auto;
    resize: vertical;

    &.code {
      padding: 1rem;
      height: 16rem;
    }

    &.ql-snow {
      border-top: none;
      height: 24rem;
    }
  }
}

.delete-button {
  cursor: pointer;
  margin-top: 5px;
  margin-right: 25px;
}

</style>
