import Vuex from 'vuex'
import axios from 'axios';
import createPersistedState from "vuex-persistedstate";
import { delay } from '@/tools/utilsTools';
//import Cookies from 'js-cookie';

import { UsersType } from '@/models/users'

import { baseURL } from '@/common/config'
import {StatisticsType} from "@/models/operators";
import {parseFullTime} from "@/tools/dateTools";
import {tryToEncryptObject} from "@/tools/cryptoTools";

import store from '@/store'

// configure axios
axios.defaults.withCredentials = true;

// enable axios interceptors -> intercept the global error
axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  const originalRequest = error.config

  console.log({
    error,
    originalRequest
  })

  // if the error is 401 and hasent already been retried
  if (error && error.response && error.response.status === 401) {

    // try to refresh user token
    store.dispatch('REFRESH_TOKEN').then(refreshTokenResponse => {

      if (refreshTokenResponse && refreshTokenResponse.success) {
        // try to refresh user token
        store.dispatch('GET_USER_CURRENT_INFO', refreshTokenResponse.token).then(res => {
          if (res.success) {
            // show message to user to repeat operation
            store.state.$notify({
              type: "warning",
              title: store.state.$t('Errors.error_not_ok'),
              text: store.state.$t('Errors.tokenWasExpiredAndRefreshed'),
            });
          }
          else {
            // show message to user to repeat operation
            store.state.$notify({
              type: "error",
              title: store.state.$t('Errors.error_not_ok'),
              text: store.state.$t('Errors.tokenIsExpired'),
            });

            // exif from account
            store.dispatch('EXIT_FROM_ACCOUNT')
          }
        })
      }
      else {
        // show message to user to repeat operation
        store.state.$notify({
          type: "error",
          title: store.state.$t('Errors.error_not_ok'),
          text: store.state.$t('Errors.tokenIsExpired'),
        });

        // exif from account
        store.dispatch('EXIT_FROM_ACCOUNT')
      }
    })
  }

  // Do something with response error
  return Promise.reject(error)
})


export default new Vuex.Store({
  strict:true,
  plugins: [
    createPersistedState({
      // storage: {
      //   getItem: key => Cookies.get(key),
      //   setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: true }),
      //   removeItem: key => Cookies.remove(key)
      // }
    })
  ],
  state: {
    $t: null,
    $notify: null,
    sideBarRolledUp: false,
    user: null,
    notifications: [],
    tips: [],
    suspicious: [],
    statistics: {
      users: {
        all: 0,
        active: 0
      },
      promotions: {
        all: 0,
        completed: 0
      }
    },
    promotions: {
      all: [],
      current: null,
      totalCount: 0,
      filter: {
        search: '',
        statuses: ['new', 'sended', 'inProcess', 'waitConfirmation', 'completed', 'cancelled']
      }
    },
    codes: {
      all: [],
      current: null,
      totalCount: 0,
      filter: {
        search: '',
        promotion: null,
        statuses: ['active', 'blocked']
      }
    },
    lists: {
      documents: [],
      prizes: [],
      questions: [],
      current: null,
      filter: {
        search: '',
        promotion: null,
        taxStatus: null,
        year: 2023,
        userStatus: 'waiting',
      }
    },
    companies: [],
    drawings: [],
    promotionParticipants: [],
    reports: [],
    participants: {
      all: [],
      filter: {
        fio: '',
        dates: {
          from: null,
          to: null
        },
        workAt: [],
        location: ''
      }
    },
    employees: {
      all: [],
      filter: {
        fio: '',
        role: null,
        company: null,
      }
    },
    list_items: {
      prizes: [],
      determining: [],
      games: [],
      filter: {
        search: '',
      }
    },
    list_phones: {
      operators: [],
      statistics: [],
      filter: {
        search: '',
        operator: null,
        type: StatisticsType.SENT,
        startedAt: null,
        endedAt: null,
      }
    },
  },
  mutations: {
    SET_GLOBAL_NOTIFICATION: (state, obj) => {
      state.$notify = obj;
    },
    SET_GLOBAL_TRANSLATE_FUNC: (state, func) => {
      state.$t = func;
    },
    SET_SIDE_BAR_STAGE: (state, value) => {
      state.sideBarRolledUp = value;
    },
    SET_USER_TO_STATE: (state, user) => {
      state.user = user;
    },
    SET_NOTIFICATIONS_TO_STATE: (state, notifications) => {
      state.notifications = notifications;
    },
    SET_STATISTICS_TO_STATE: (state, statistics) => {
      state.statistics = statistics;
    },
    SET_PARTICIPANTS_TO_STATE: (state, participants) => {
      state.participants = participants;
    },
    SET_PARTICIPANTS_FILTER_TO_STATE: (state, filter) => {
      state.participants.filter = filter;
    },
    SET_EMPLOYEES_TO_STATE: (state, employees) => {
      state.employees = employees;
    },
    SET_EMPLOYEES_FILTER_TO_STATE: (state, filter) => {
      state.employees.filter = filter;
    },
    // PROMOTIONS
    SET_PROMOTIONS_TO_STATE: (state, promotions) => {
      state.promotions = promotions;
    },
    SET_PROMOTIONS_FILTER_TO_STATE: (state, filter) => {
      state.promotions.filter = filter;
    },
    // TIPS
    SET_TIPS_TO_STATE: (state, tips) => {
      state.tips = tips;
    },
    // SUSPICIOUS
    SET_SUSPICIOUS_TO_STATE: (state, suspicious) => {
      state.suspicious = suspicious;
    },
    // COMPANIES
    SET_COMPANIES_TO_STATE: (state, companies) => {
      state.companies = companies;
    },
    // PROMOTION PARTICIPANTS
    SET_PROMOTION_PARTICIPANTS_TO_STATE: (state, data) => {
      state.promotionParticipants = data;
    },
    // REPORTS
    SET_REPORTS_TO_STATE: (state, data) => {
      state.reports = data;
    },
    // CODES
    SET_CODES_TO_STATE: (state, codes) => {
      state.codes = codes;
    },
    SET_CODES_FILTER_TO_STATE: (state, filter) => {
      state.codes.filter = filter;
    },
    // LISTS
    SET_LISTS_TO_STATE: (state, lists) => {
      state.lists = lists;
    },
    SET_LISTS_FILTER_TO_STATE: (state, filter) => {
      state.lists.filter = filter;
    },
    // LIST_ITEMS
    SET_LIST_ITEMS_TO_STATE: (state, list_items) => {
      state.list_items = list_items;
    },
    SET_LIST_ITEMS_FILTER_TO_STATE: (state, filter) => {
      state.list_items.filter = filter;
    },
    // LIST_PHONES
    SET_LIST_PHONES_TO_STATE: (state, list_phones) => {
      state.list_phones = list_phones;
    },
    SET_LIST_PHONES_FILTER_TO_STATE: (state, filter) => {
      state.list_phones.filter = filter;
    },
    SET_LIST_PHONES_STATISTICS_TO_STATE: (state, statistics) => {
      state.list_phones.statistics = statistics ? statistics : [];
    },
    // DRAWINGS
    SET_DRAWINGS_TO_STATE: (state, drawings) => {
      state.drawings = drawings ? drawings : [];
    },
  },
  actions: {
    SET_NOTIFICATION_FUNCTION({ commit }, obj) {
      commit('SET_GLOBAL_NOTIFICATION', obj)
    },
    SET_TRANSLATE_FUNCTION({ commit }, func) {
      commit('SET_GLOBAL_TRANSLATE_FUNC', func)
    },
    SET_SIDE_BAR_STAGE_FUNCTION({ commit }, value) {
      commit('SET_SIDE_BAR_STAGE', value)
    },
    LOGIN_WITH_PASSWORD({ dispatch }, user) {
      user.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      // console.log({
      //   user, crypted: tryToEncryptObject(user)
      // })

      return axios.post(`${baseURL}users/loginWithPassword`, tryToEncryptObject(user)).then(loginRes => {
        if (loginRes.data.success) {
          return dispatch('GET_USER_CURRENT_INFO', loginRes.data.token).then(getInfoRes => {
            return getInfoRes;
          })
        } else
          return loginRes.data;
      })
    },
    // eslint-disable-next-line no-unused-vars
    RECOVERY_PASSWORD({ dispatch, state }, user) {
      return axios.post(`${baseURL}users/recovery`, user).then(loginRes => {
          return loginRes.data;
      })
    },
    // eslint-disable-next-line no-unused-vars
    REFRESH_TOKEN({ state }) {
      let body = {
        token: state.user ? state.user.token : ''
      }

      return axios.post(`${baseURL}users/refreshToken`, tryToEncryptObject(body), {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {
        return response.data;
      })
    },

    // eslint-disable-next-line no-unused-vars
    GET_USER_CURRENT_INFO({ commit, state, dispatch }, token) {
      return axios.get(`${baseURL}users/current`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(res => {
        if (res.data.success && res.data.user.role === UsersType.PARTICIPANT) {
          res.data.error = 'hasNoAccessToThePlatform'
          return { ...res.data, success: false };
        }
        else if (res.data.success && res.data.user.role === UsersType.CALL_CENTER_OPERATOR) {
          res.data.error = 'hasNoAccessToThePlatform'
          return { ...res.data, success: false };
        }
        else if (res.data.success) {
          commit('SET_USER_TO_STATE', { ...res.data.user, token })

          // check if user has any company for non admin user
          if(state.user && !state.user.isAdmin && state.user.companies) {
            if(state.user.companies.length === 0) {

              // clear global company filter
              dispatch('SET_EMPLOYEES_FILTER', {
                ...state.employees.filter,
                company: null
              })
            }
          }

          return res.data;
        } else {
          return { ...res.data, success: false };
        }
      }).catch(err => {
        return err;
      })
    },
    EXIT_FROM_ACCOUNT({ commit }) {
      commit('SET_USER_TO_STATE', null)
      commit('SET_NOTIFICATIONS_TO_STATE', [])
      commit('SET_STATISTICS_TO_STATE', {
        users: {
          all: 0,
          active: 0
        },
        promotions: {
          all: 0,
          completed: 0
        }
      })
      commit('SET_PARTICIPANTS_TO_STATE', {
        all: [],
        filter: {
          fio: '',
          dates: {
            from: null,
            to: null
          },
          workAt: [],
          location: ''
        }
      })
      commit('SET_EMPLOYEES_TO_STATE', {
        all: [],
        filter: {
          fio: '',
          role: null,
          company: null,
        }
      })
      commit('SET_PROMOTIONS_TO_STATE', {
        all: [],
        current: null,
        totalCount: 0,
        filter: {
          search: '',
          statuses: ['new', 'sended', 'inProcess', 'waitConfirmation', 'completed', 'cancelled']
        }
      })
      commit('SET_TIPS_TO_STATE', [])
      commit('SET_SUSPICIOUS_TO_STATE', [])
      commit('SET_COMPANIES_TO_STATE', [])
      commit('SET_PROMOTION_PARTICIPANTS_TO_STATE', [])
      commit('SET_REPORTS_TO_STATE', [])
      commit('SET_CODES_TO_STATE', {
        all: [],
        current: null,
        totalCount: 0,
        filter: {
          search: '',
          promotion: null,
          statuses: ['active', 'blocked']
        }
      })
      commit('SET_LISTS_TO_STATE', {
        documents: [],
        prizes: [],
        questions: [],
        current: null,
        filter: {
          search: '',
          promotion: null,
          taxStatus: null,
          year: 2023,
          userStatus: 'waiting',
        }
      })
      commit('SET_LIST_ITEMS_TO_STATE', {
        prizes: [],
        determining: [],
        games: [],
        filter: {
          search: '',
        }
      })
      commit('SET_LIST_PHONES_TO_STATE', {
        operators: [],
        statistics: [],
        filter: {
          search: '',
          operator: null,
          type: StatisticsType.SENT,
          startedAt: null,
          endedAt: null,
        }
      })
      commit('SET_DRAWINGS_TO_STATE', [])
    },

    CLEAR_CODES({ commit, state }) {
      commit('SET_CODES_TO_STATE', {
        all: [],
        current: null,
        totalCount: 0,
        filter: state.codes.filter
      })
    },
    CLEAR_LISTS({ commit, state }) {
      console.log('CLEAR_LISTS')
      commit('SET_LISTS_TO_STATE', {
        documents: [],
        questions: [],
        current: null,
        totalCount: 0,
        filter: state.lists.filter
      })
    },
    GET_LAST_NOTIFICATIONS({ commit, state }) {
      return axios.get(`${baseURL}notifications/last`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(notificationsRes => {
        if (notificationsRes.data.success) {
          commit('SET_NOTIFICATIONS_TO_STATE', notificationsRes.data.notifications)
        }
        return notificationsRes.data;
      }).catch(err => {
        return err;
      })
    },
    // eslint-disable-next-line no-empty-pattern
    GET_NOTIFICATIONS_HISTORY({ state }, data) {
      return axios.get(`${baseURL}notifications/download?from=${data.from}&to=${data.to}&format=${data.format}`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {

        let blob = new Blob([res.data], { type: data.format == 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${data.from}  ${data.to}.${data.format == 'xml' ? 'xml' : 'xls'}`
        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    SEND_NOTIFICATION({ commit, state }, data) {
      return axios.post(`${baseURL}notifications/send`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(sendNotificationRes => {
        if (sendNotificationRes.data.success) {
          commit('SET_NOTIFICATIONS_TO_STATE', [sendNotificationRes.data.notification, ...state.notifications])
        }
        return sendNotificationRes.data;
      })
    },
    // eslint-disable-next-line no-unused-vars
    GET_MAIN_STATISTICS({ commit, state }) {
    },
    GET_PARTICIPANTS_FROM({ commit, state }, data) {
      return axios.get(`${baseURL}users/all?role=participant&from=${data.from || 0}`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_PARTICIPANTS_TO_STATE', {
            all: res.data.users,
            filter: state.participants.filter
          })
        } else {
          commit('SET_PARTICIPANTS_TO_STATE', {
            all: [],
            filter: state.participants.filter
          })
        }
        return res.data;
      }).catch(err => {
        commit('SET_PARTICIPANTS_TO_STATE', {
          all: [],
          filter: state.participants.filter
        })
        return err;
      })
    },
    SET_PARTICIPANTS_FILTER({ commit }, filter) {
      commit('SET_PARTICIPANTS_FILTER_TO_STATE', filter)
    },
    SET_EMPLOYEES_FILTER({ commit }, filter) {
      commit('SET_EMPLOYEES_FILTER_TO_STATE', filter)
    },
    SET_PROMOTIONS_FILTER({ commit }, filter) {
      commit('SET_PROMOTIONS_FILTER_TO_STATE', filter)
    },
    SET_CODES_FILTER({ commit }, filter) {
      commit('SET_CODES_FILTER_TO_STATE', filter)
    },
    SET_LISTS_FILTER({ commit }, filter) {
      commit('SET_LISTS_FILTER_TO_STATE', filter)
    },
    SET_LIST_ITEMS_FILTER({ commit }, filter) {
      commit('SET_LIST_ITEMS_FILTER_TO_STATE', filter)
    },
    SET_LIST_PHONES_FILTER({ commit }, filter) {
      commit('SET_LIST_PHONES_FILTER_TO_STATE', filter)
    },
    SET_LIST_PHONES_STATISTICS({ commit }, statistics) {
      commit('SET_LIST_PHONES_STATISTICS_TO_STATE', statistics)
    },

    // eslint-disable-next-line no-empty-pattern
    GET_PROMOTIONS_DOWNLOAD({ state }, data) {
      return axios.get(`${baseURL}promotions/download?format=${data.format}&company=${data.company}`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Акции ${parseFullTime(new Date())}.${data.format === 'xml' ? 'xml' : 'xls'}`
        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    GET_PROMOTIONS_FROM({ commit, state }, data) {
      return axios.get(`${baseURL}promotions/all`, {
        params: {
          search: data.search,
          company: data.company,
        },
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_PROMOTIONS_TO_STATE', {
            all: res.data.promotions,
            filter: state.promotions.filter
          })
        } else {
          commit('SET_PROMOTIONS_TO_STATE', {
            all: [],
            filter: state.promotions.filter
          })
        }

        return res.data;
      }).catch(err => {
        commit('SET_PROMOTIONS_TO_STATE', {
          all: [],
          filter: state.promotions.filter
        })
        return err;
      })
    },
    GET_CURRENT_PROMOTION({ commit, state }, data) {
      return axios.get(`${baseURL}promotions/current`, {
        params: {
          number: data.number
        },
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          // check if promotions pool contains current promotion
          if(state.promotions.all == null) {
            state.promotions.all = []
            state.promotions.all.push(res.data.promotion)
          }
          else if(state.promotions.all.filter(v => v.id.toString() === res.data.promotion.id.toString()).length === 0) {
            state.promotions.all.push(res.data.promotion)
          }

          commit('SET_PROMOTIONS_TO_STATE', {
            current: res.data.promotion,
            all: state.promotions.all,
            filter: state.promotions.filter
          })
        } else {
          commit('SET_PROMOTIONS_TO_STATE', {
            current: null,
            all: state.promotions.all,
            filter: state.promotions.filter
          })
        }
        return res.data;
      }).catch(err => {
        commit('SET_PROMOTIONS_TO_STATE', {
          current: null,
          all: state.promotions.all,
          filter: state.promotions.filter
        })
        return err;
      })
    },

    GET_CODES_FROM({ commit, state }, data) {
      return axios.get(`${baseURL}codes/all`, {
        params: {
          search: data.search,
          promotion: data.promotion,
          page: data.page,
        },
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          let newPromotion = false;
          let codes = state.codes.all ? [...state.codes.all] : [];

          if(state.codes.current !== res.data.promotion) {
            newPromotion = true;
          }

          if(newPromotion) {
            codes = [...res.data.codes]
          }
          else {
            //for (var i = 0; i < res.data.codes.length; i++) {
            //   let code = res.data.codes[i];
            for (let code of res.data.codes) {

              // old way to update the list
              if(codes.filter(item => item.id === code.id).length === 0)
                codes.push(code)

              // check the object exists in the list
              if (codes.find((item) => item.id === code.id)) {
                // try to update the item
                const index = codes.findIndex((item) => item.id === code.id)
                const indexItem = codes[index]

                codes[index] = {...indexItem, ...code}
              }
              else {
                // just add the item to the list
                codes.push(code)
              }
            }
          }

          commit('SET_CODES_TO_STATE', {
            all: codes,
            current: res.data.promotion,
            totalCount: res.data.totalCount ? res.data.totalCount : codes.length,
            filter: state.codes.filter
          })
        } else {
          commit('SET_CODES_TO_STATE', {
            all: [],
            current: data.promotion,
            totalCount: 0,
            filter: state.codes.filter
          })
        }

        return res.data;
      }).catch(err => {
        commit('SET_CODES_TO_STATE', {
          all: [],
          current: data.promotion,
          totalCount: 0,
          filter: state.codes.filter
        })
        return err;
      })
    },

    GENERATE_CODES({ commit, state }, data) {
      data.notify = true
      return axios.post(`${baseURL}codes/generate`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {
        if (response.data.success) {
          // save promotion state
          commit('SET_PROMOTIONS_TO_STATE', {
            all: state.promotions.all.map(v => v.id === response.data.promotion.id ? response.data.promotion : v),
            filter: state.promotions.filter
          })

          // save codes state
          // commit('SET_CODES_TO_STATE', {
          //   all: response.data.codes,
          //   filter: state.codes.filter
          // })
        }

        return response.data;
      }).catch( () => {
        return {success: false}
      })
    },

    ACTIVATE_CODE({ commit, state }, data) {
      return axios.post(`${baseURL}codes/activate`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(activateCode => {
        if (activateCode.data.success) {
          let all = state.codes.all.map(v => v.id === activateCode.data.code.id ? activateCode.data.code : v);
          commit('SET_CODES_TO_STATE', {
            all: all,
            filter: state.codes.filter,
            current: state.codes.current,
            totalCount: all.length,
          })
        }

        return activateCode.data;
      })
    },

    DEACTIVATE_CODE({ commit, state }, data) {
      return axios.post(`${baseURL}codes/deactivate`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(activateCode => {
        if (activateCode.data.success) {
          if(data.id) {
            let all = state.codes.all.map(v => v.id === activateCode.data.code.id ? activateCode.data.code : v);

            commit('SET_CODES_TO_STATE', {
              all: all,
              filter: state.codes.filter,
              current: state.codes.current,
              totalCount: all.length,
            })
          }
        }

        return activateCode.data;
      })
    },

    // eslint-disable-next-line no-unused-vars
    DELETE_CODE({ commit, state }, data) {
      return axios.post(`${baseURL}codes/delete`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(activateCode => {
        if (activateCode.data.success) {
          // code is deleted
          let all = state.codes.all.filter(v => v.id !== data.id);
          commit('SET_CODES_TO_STATE', {
            all: all,
            filter: state.codes.filter,
            current: state.codes.current,
            totalCount: all.length,
          })
        }

        return activateCode.data;
      })
    },

    GET_PROMOTION_PARTICIPANTS_FROM({ commit, state }, data) {
      return axios.get(`${baseURL}participants/all`, {
        params: {
          promotion: data.promotion,
        },
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_PROMOTION_PARTICIPANTS_TO_STATE', res.data.participants)
        } else {
          commit('SET_PROMOTION_PARTICIPANTS_TO_STATE', [])
        }

        return res.data;
      }).catch(err => {
        commit('SET_PROMOTION_PARTICIPANTS_TO_STATE', [])

        return err;
      })
    },

    GET_WINNERS({ commit, state }, data) {
      return axios.get(`${baseURL}participants/winners`, {
        params: {
          search: data.search,
          promotion: data.promotion,
        },
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        console.log('GET_WINNERS')

        if (res.data.success) {
          commit('SET_LISTS_TO_STATE', {
            documents: res.data.winners,
            prizes: state.lists.prizes,
            questions: state.lists.questions,
            current: data.promotion,
            filter: state.lists.filter
          })
        } else {
          commit('SET_LISTS_TO_STATE', {
            documents: [],
            prizes: state.lists.prizes,
            questions: state.lists.questions,
            current: data.promotion,
            filter: state.lists.filter
          })
        }

        return res.data;
      }).catch(err => {
        commit('SET_LISTS_TO_STATE', {
          documents: [],
          prizes: state.lists.prizes,
          questions: state.lists.questions,
          current: data.promotion,
          filter: state.lists.filter
        })
        return err;
      })
    },

    GET_PROMOTION_BILLS({ state }, data) {
      return axios.get(`${baseURL}participants/check/bills`, {
        params: data,
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        return res.data;
      }).catch(err => {
        return err;
      })
    },

    GET_PROMOTION_BILL({ state }, data) {
      return axios.get(`${baseURL}participants/check/bill`, {
        params: {
          id: data.id,
        },
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        return res.data;
      }).catch(err => {
        return err;
      })
    },

    GET_PROMOTION_PRIZES({ state }, data) {
      return axios.get(`${baseURL}participants/prizes/all`, {
        params: {
          search: data.search,
          promotion: data.promotion,
          page: data.page,
          count: data.count,
          status: data.status,
        },
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        return res.data;
      }).catch(err => {
        return err;
      })
    },

    GET_PROMOTION_PRIZE({ state }, data) {
      return axios.get(`${baseURL}participants/prizes/get`, {
        params: {
          id: data.id,
        },
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        return res.data;
      }).catch(err => {
        return err;
      })
    },


    GET_USERS_TAXES({ state }, data) {
      return axios.get(`${baseURL}participants/taxes`, {
        params: data,
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        return res.data;
      }).catch(err => {
        return err;
      })
    },

    GET_PRIZES({ commit, state }, data) {
      return axios.get(`${baseURL}prizes/all`, {
        params: {
          search: data.search,
        },
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_LIST_ITEMS_TO_STATE', {
            prizes: res.data.prizes,
            determining: state.list_items.determining,
            games: state.list_items.games,
            filter: state.list_items.filter
          })
        } else {
          commit('SET_LIST_ITEMS_TO_STATE', {
            prizes: [],
            determining: state.list_items.determining,
            games: state.list_items.games,
            filter: state.list_items.filter
          })
        }

        return res.data;
      }).catch(err => {
        commit('SET_LIST_ITEMS_TO_STATE', {
          prizes: [],
          determining: state.list_items.determining,
          games: state.list_items.games,
          filter: state.list_items.filter
        })
        return err;
      })
    },
    CREATE_PRIZE({ state, dispatch }, data) {
      return axios.post(`${baseURL}prizes/create`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          dispatch('GET_PRIZES', {
            search: state.list_items.filter.search ? state.list_items.filter.search : null
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    UPDATE_PRIZE({ state, dispatch }, data) {
      return axios.post(`${baseURL}prizes/update`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          dispatch('GET_PRIZES', {
            search: state.list_items.filter.search ? state.list_items.filter.search : null
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    GET_PRIZES_DOWNLOAD({ state }, data) {
      return axios.get(`${baseURL}prizes/download?format=${data.format}`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Призы ${parseFullTime(new Date())}.${data.format}`
        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    // eslint-disable-next-line no-empty-pattern
    UPLOAD_PRIZE_FILES({ state }, data) {
      return axios.post(`${baseURL}prizes/upload-files?promotion=${data.promotion}&prize=${data.prize}&replace=${data.replace}`, data.file, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {
        return response.data;
      });
    },
    GET_DETERMINING({ commit, state }, data) {
      return axios.get(`${baseURL}determinings/all`, {
        params: {
          search: data.search,
        },
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_LIST_ITEMS_TO_STATE', {
            prizes: state.list_items.prizes,
            determining: res.data.determinings,
            games: state.list_items.games,
            filter: state.list_items.filter
          })
        } else {
          commit('SET_LIST_ITEMS_TO_STATE', {
            prizes: state.list_items.prizes,
            determining: [],
            games: state.list_items.games,
            filter: state.list_items.filter
          })
        }

        return res.data;
      }).catch(err => {
        commit('SET_LIST_ITEMS_TO_STATE', {
          prizes: state.list_items.prizes,
          determining: [],
          games: state.list_items.games,
          filter: state.list_items.filter
        })
        return err;
      })
    },
    CREATE_DETERMINING({ state, dispatch }, data) {
      return axios.post(`${baseURL}determinings/create`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          dispatch('GET_DETERMINING', {
            search: state.list_items.filter.search ? state.list_items.filter.search : null
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    UPDATE_DETERMINING({ state, dispatch }, data) {
      return axios.post(`${baseURL}determinings/update`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          dispatch('GET_DETERMINING', {
            search: state.list_items.filter.search ? state.list_items.filter.search : null
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    // eslint-disable-next-line no-unused-vars
    DELETE_DETERMINING({ commit, state, dispatch }, data) {
      return axios.post(`${baseURL}determinings/delete`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if(res.data.success) {
          dispatch('GET_DETERMINING', {})
        }

        return res.data;
      })
    },
    GET_DETERMINING_DOWNLOAD({ state }, data) {
      return axios.get(`${baseURL}determinings/download?format=${data.format}`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Способы определения победителя ${parseFullTime(new Date())}.${data.format}`
        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    GET_GAMES({ commit, state }, data) {
      return axios.get(`${baseURL}games/all`, {
        params: {
          search: data.search,
        },
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_LIST_ITEMS_TO_STATE', {
            prizes: state.list_items.prizes,
            determining: state.list_items.determining,
            games: res.data.games,
            filter: state.list_items.filter
          })
        } else {
          commit('SET_LIST_ITEMS_TO_STATE', {
            prizes: state.list_items.prizes,
            determining: state.list_items.determining,
            games: [],
            filter: state.list_items.filter
          })
        }

        return res.data;
      }).catch(err => {
        commit('SET_LIST_ITEMS_TO_STATE', {
          prizes: state.list_items.prizes,
          determining: state.list_items.determining,
          games: [],
          filter: state.list_items.filter
        })
        return err;
      })
    },
    CREATE_GAME({ state, dispatch }, data) {
      return axios.post(`${baseURL}games/create`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          dispatch('GET_GAMES', {
            search: state.list_items.filter.search ? state.list_items.filter.search : null
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    UPDATE_GAME({ state, dispatch }, data) {
      return axios.post(`${baseURL}games/update`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          dispatch('GET_GAMES', {
            search: state.list_items.filter.search ? state.list_items.filter.search : null
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    // eslint-disable-next-line no-unused-vars
    DELETE_GAME({ commit, state, dispatch }, data) {
      return axios.post(`${baseURL}games/delete`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if(res.data.success) {
          dispatch('GET_GAMES', {})
        }

        return res.data;
      })
    },
    GET_GAMES_DOWNLOAD({ state }, data) {
      return axios.get(`${baseURL}games/download?format=${data.format}`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Игры ${parseFullTime(new Date())}.${data.format}`
        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    UPLOAD_PRIZE_REQUISITE({ state }, data) {
      return axios.post(`${baseURL}participants/prizes/file?id=${data.id}&name=${data.name}`, data.scan, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {
        return response.data;
      });
    },
    UPLOAD_PRIZES_REQUISITE({ state }, data) {
      return axios.post(`${baseURL}participants/prizes/prizes/file?ids=${data.ids}&name=${data.name}`, data.scan, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {
        return response.data;
      });
    },


    GET_OPERATORS({ commit, state }, data) {
      return axios.get(`${baseURL}phones/all`, {
        params: {
          search: data.search,
        },
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_LIST_PHONES_TO_STATE', {
            operators: res.data.operators,
            statistics: state.list_phones.statistics,
            filter: state.list_phones.filter
          })
        } else {
          commit('SET_LIST_PHONES_TO_STATE', {
            operators: [],
            statistics: state.list_phones.statistics,
            filter: state.list_phones.filter
          })
        }

        return res.data;
      }).catch(err => {
        commit('SET_LIST_PHONES_TO_STATE', {
          operators: [],
          statistics: state.list_phones.statistics,
          filter: state.list_phones.filter
        })
        return err;
      })
    },
    CREATE_OPERATOR({ state, dispatch }, data) {
      return axios.post(`${baseURL}phones/create`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          dispatch('GET_OPERATORS', {
            search: state.list_phones.filter.search ? state.list_phones.filter.search : null
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    UPDATE_OPERATOR({ state, dispatch }, data) {
      return axios.post(`${baseURL}phones/update`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          dispatch('GET_OPERATORS', {
            search: state.list_phones.filter.search ? state.list_phones.filter.search : null
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    GET_OPERATORS_DOWNLOAD({ state }, data) {
      return axios.get(`${baseURL}phones/download?format=${data.format}`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Операторы ${parseFullTime(new Date())}.${data.format}`
        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },

    GET_OPERATOR_STATISTICS({ state }, data) {
      return axios.get(`${baseURL}phones/reports`, {
        params: data,
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        return res.data;
      }).catch(err => {
        return err;
      })
    },

    GET_OPERATOR_BALANCE({ state }, data) {
      return axios.get(`${baseURL}phones/balance`, {
        params: data,
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    CREATE_SMS({ state, dispatch }, data) {
      return axios.post(`${baseURL}phones/send`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          dispatch('GET_OPERATORS', {
            search: state.list_phones.filter.search ? state.list_phones.filter.search : null
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },

    GET_REPORTS_FROM({ commit, state }, data) {
      return axios.get(`${baseURL}reports/all`, {
        params: data,
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_REPORTS_TO_STATE', res.data.reports)
        } else {
          commit('SET_REPORTS_TO_STATE', [])
        }

        return res.data;
      }).catch(err => {
        commit('SET_REPORTS_TO_STATE', [])
        return err;
      })
    },
    CREATE_REPORT({ state, dispatch }, data) {
      return axios.post(`${baseURL}reports/create`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          dispatch('GET_REPORTS_FROM', {
            company: state.employees.filter ? state.employees.filter.company : null,
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    UPDATE_REPORT({ state, dispatch }, data) {
      return axios.post(`${baseURL}reports/update`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          dispatch('GET_REPORTS_FROM', {
            company: state.employees.filter ? state.employees.filter.company : null,
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },

    // eslint-disable-next-line no-unused-vars
    DELETE_REPORT({ commit, state, dispatch }, data) {
      return axios.post(`${baseURL}reports/delete`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if(res.data.success) {
          dispatch('GET_REPORTS_FROM', {
            company: state.employees.filter ? state.employees.filter.company : null,
          })
        }

        return res.data;
      })
    },
    GET_DELIVERY_REPORT({ state }, data) {
      return axios.post(`${baseURL}reports/delivery`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Логистика: ${data.name}.${data.format}`

        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    GET_MONEY_REPORT({ state }, data) {
      return axios.post(`${baseURL}reports/money`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Деньги: ${data.name}.${data.format}`

        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    GET_PARTICIPANTS_REPORT({ state }, data) {
      return axios.post(`${baseURL}reports/participants`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Участники: ${data.name}.${data.format}`

        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    GET_PRIZES_REPORT({ state }, data) {
      return axios.post(`${baseURL}reports/prizes`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Призы: ${data.name}.${data.format}`

        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    GET_BILLS_REPORT({ state }, data) {
      return axios.post(`${baseURL}reports/bills`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Чеки: ${data.name}.${data.format}`

        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    GET_CODES_REPORT({ state }, data) {
      return axios.post(`${baseURL}reports/codes`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Коды: ${data.name}.${data.format}`

        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    GET_TAXES_DOWNLOAD({ state }, data) {
      return axios.get(`${baseURL}participants/prizes/download/taxes`, {
        params: data,
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Бухгалтерские документы ${data.name ? data.name : ''} ${parseFullTime(new Date())}.${data.format}`
        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    GET_USERS_TAXES_DOWNLOAD({ state }, data) {
      return axios.get(`${baseURL}participants/prizes/download/users/taxes`, {
        params: data,
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Контроль налогов ${data.year} ${parseFullTime(new Date())}.${data.format}`
        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    GET_DOCUMENTS_DOWNLOAD({ state }, data) {
      return axios.get(`${baseURL}participants/prizes/download/documents?format=${data.format}&promotion=${data.promotion}`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Призы и документы ${data.name ? data.name : ''} ${parseFullTime(new Date())}.${data.format}`
        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    GET_PROMOTION_QUESTIONS({ commit, state }, data) {
      return axios.get(`${baseURL}questions/all`, {
        params: {
          search: data.search,
          promotion: data.promotion,
          empty: data.empty,
        },
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_LISTS_TO_STATE', {
            documents: state.lists.documents,
            prizes: state.lists.prizes,
            questions: res.data.questions,
            current: data.promotion,
            filter: state.lists.filter
          })
        } else {
          commit('SET_LISTS_TO_STATE', {
            documents: state.lists.documents,
            prizes: state.lists.prizes,
            questions: [],
            current: data.promotion,
            filter: state.lists.filter
          })
        }

        return res.data;
      }).catch(err => {
        commit('SET_LISTS_TO_STATE', {
          documents: state.lists.documents,
          prizes: state.lists.prizes,
          questions: [],
          current: data.promotion,
          filter: state.lists.filter
        })
        return err;
      })
    },

    UPDATE_PROMOTION_QUESTION({ state }, data) {
      return axios.post(`${baseURL}questions/update`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        return res.data;
      })
    },

    CLEAR_CURRENT_PROMOTION({ commit, state }) {
      commit('SET_PROMOTIONS_TO_STATE', {
        current: null,
        all: state.promotions.all,
        filter: state.promotions.filter
      })
    },
    VIEW_INSPECTION_MESSAGE({ commit, state }, promotion) {
      commit('SET_PROMOTIONS_TO_STATE', {
        all: state.promotions.all.map(v => v.id == promotion.id ? ({ ...v, newMessages: false }) : v),
        filter: state.promotions.filter
      })
    },
    // eslint-disable-next-line no-empty-pattern
    GET_PROMOTIONE_FILES({ state }, data) {
      return axios.get(`${baseURL}promotions/download?id=${data.id}`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {

        let blob = new Blob([res.data], { type: 'application/octet-stream' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${data.id}.zip`
        link.click()

      }).catch(err => {
        return err;
      })
    },
    REQUERY_PROMOTION({ commit, state }, data) {
      return axios.post(`${baseURL}promotions/requery`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_PROMOTIONS_TO_STATE', {
            ...state.promotions,
            all: state.promotions.all.map(v => v.id.toString() == res.data.promotion.id.toString() ? ({ ...v, ...res.data.promotion }) : v),
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    ADD_PROMOTION_MESSAGE({ commit, state }, data) {
      commit('SET_PROMOTIONS_TO_STATE', {
        ...state.promotions,
        all: state.promotions.all.map(v => v.id.toString() == data.promotion.toString() ? ({ ...v, newMessages: true, messages: [...v.messages, data.message] }) : v),
      })
    },
    SEND_PROMOTION_MESSAGE({ commit, state }, data) {
      return axios.post(`${baseURL}promotions/send-message`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_PROMOTIONS_TO_STATE', {
            ...state.promotions,
            all: state.promotions.all.map(v => v.id.toString() === res.data.promotion.toString() ? ({ ...v, messages: [...v.messages, res.data.message] }) : v),
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },

    VIEW_USER_MESSAGE({ commit, state }, employee) {
      commit('SET_EMPLOYEES_TO_STATE', {
        all: state.employees.all.map(v => v.id === employee.id ? ({ ...v, newMessages: false }) : v),
        filter: state.employees.filter
      })

      if(employee.id === state.user.id) {
        commit('SET_USER_TO_STATE', ({ ...state.user, newMessages: false }))
      }
    },
    ADD_USER_MESSAGE({ commit, state }, data) {
      commit('SET_EMPLOYEES_TO_STATE', {
        ...state.employees,
        all: state.employees.all.map(v => v.id.toString() === data.user.toString() ? ({ ...v, newMessages: true, messages: [...v.messages, data.message] }) : v),
      })

      // save message to current user
      if(state.user && state.user.id === data.user.toString()) {
        commit('SET_USER_TO_STATE', ({ ...state.user, newMessages: state.user.id !== data.message.user_id, messages: [...state.user.messages, data.message] }))

        if(state.$notify && state.user.id !== data.message.user_id) {
          state.$notify({
            type: "success",
            title: state.$t('ChatText.new_message_notify'),
            text: data.message.message,
          });
        }
      }
    },

    VIEW_COMPANY_MESSAGE({ commit, state }, company) {
      commit('SET_COMPANIES_TO_STATE',
        state.companies.map(v => v.id === company.id ? ({ ...v, newMessages: false }) : v)
      )
    },
    ADD_COMPANY_MESSAGE({ commit, state }, data) {
      console.log('ADD_COMPANY_MESSAGE')
      console.log(data)

      let showNotification = false

      // check if need to show message to user
      if(state.user) {

        // check if current user is message owner
        if(state.user.id !== data.message.user_id) {

          // show for all admin users
          if(state.user && (state.user.role === UsersType.ADMIN)) {
            showNotification = true
          }
          // check if user has access to company
          else {
            let companies = state.user ? state.user.companies : []

            if(companies.find(c => c.id === data.company))
              showNotification = true
          }

        }

        // show notifications about new message
        if(showNotification && state.$notify) {
          state.$notify({
            type: "success",
            title: state.$t('ChatText.new_company_message_notify'),
            text: data.message.message,
          });
        }
      }

      // save message information
      commit('SET_COMPANIES_TO_STATE',
          state.companies.map(v => v.id.toString() === data.company.toString() ? ({ ...v, newMessages: showNotification, messages: [...v.messages, data.message] }) : v)
      )
    },

    CREATE_PROMOTION({ state, dispatch }, data) {
      return axios.post(`${baseURL}promotions/create`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          dispatch('GET_PROMOTIONS_FROM', {
            company: state.employees.filter ? state.employees.filter.company : null
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },

    // eslint-disable-next-line no-unused-vars
    DELETE_PROMOTION({ commit, state, dispatch }, data) {
      return axios.post(`${baseURL}promotions/delete`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if(res.data.success) {
          dispatch('GET_PROMOTIONS_FROM', {
            company: state.employees.filter ? state.employees.filter.company : null
          })
        }

        return res.data;
      })
    },

    UPDATE_PROMOTION({ commit, state }, data) {
      return axios.post(`${baseURL}promotions/update`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_PROMOTIONS_TO_STATE', {
            ...state.promotions,
            all: state.promotions.all.map(v => v.id.toString() == res.data.promotion.id.toString() ? ({ ...v, ...res.data.promotion }) : v),
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    CHANGE_PROMOTION({ commit, state }, data) {
      commit('SET_PROMOTIONS_TO_STATE', {
        ...state.promotions,
        all: state.promotions.all.find(v => v.id.toString() == data.promotion.id.toString()) ? state.promotions.all.map(v => v.id.toString() == data.promotion.id.toString() ? ({ ...v, ...data.promotion }) : v) : [...state.promotions.all, data.promotion],
      })
    },


    // TIPS
    GET_TIPS_FROM({ commit, state }) {
      return axios.get(`${baseURL}tips/all`, {
        params: {
        },
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        try {
          if (res.data.success) {
            commit('SET_TIPS_TO_STATE', res.data.tips)
          } else {
            commit('SET_TIPS_TO_STATE', [])
          }
        }
        catch (e) {
          console.log(e)
        }

        return res.data;
      }).catch(err => {
        commit('SET_TIPS_TO_STATE', [])
        return err;
      })
    },


    // GET TIP
    GET_TIP_FROM({ state }, data) {
      return axios.get(`${baseURL}tips/get`, {
        params: {
          id: data.id
        },
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        return res.data;
      }).catch(err => {
        return err;
      })
    },

    CREATE_TIP({ state, dispatch }, data) {
      return axios.post(`${baseURL}tips/create`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          dispatch('GET_TIPS_FROM')
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },

    // eslint-disable-next-line no-unused-vars
    DELETE_TIP({ commit, state, dispatch }, data) {
      return axios.post(`${baseURL}tips/delete`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if(res.data.success) {
          dispatch('GET_TIPS_FROM')
        }

        return res.data;
      })
    },

    UPDATE_TIP({ commit, state }, data) {
      return axios.post(`${baseURL}tips/update`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_TIPS_TO_STATE',
            state.tips.map(v => v.id.toString() === res.data.tip.id.toString() ? ({ ...v, ...res.data.tip }) : v))
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },

    // eslint-disable-next-line no-empty-pattern
    UPLOAD_TIP_IMAGE({ state }, data) {
      return axios.post(`${baseURL}tips/image?id=${data.id}`, data.scan, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {
        return response.data;
      });
    },

    CHANGE_TIP({ commit, state }, data) {
      if(data.tip) {
        commit('SET_TIPS_TO_STATE',
          state.tips.find(v => v.id.toString() === data.tip.id.toString()) ? state.tips.map(v => v.id.toString() === data.tip.id.toString() ? ({ ...v, ...data.tip }) : v) : [...state.tips, data.tip]
        )
      }
    },


    // SUSPICIOUS
    GET_SUSPICIOUS_FROM({ commit, state }, params) {
      return axios.get(`${baseURL}scams/all`, {
        params: params,
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_SUSPICIOUS_TO_STATE', res.data.suspicious)
        } else {
          commit('SET_SUSPICIOUS_TO_STATE', [])
        }

        return res.data;
      }).catch(err => {
        commit('SET_SUSPICIOUS_TO_STATE', [])
        return err;
      })
    },

    GET_SUSPICIOUS_DOWNLOAD({ state }, data) {
      return axios.get(`${baseURL}scams/download?format=${data.format}&company=${data.company}`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Подозрительные участники ${parseFullTime(new Date())}.${data.format === 'xml' ? 'xml' : 'xls'}`
        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },

    UPDATE_SUSPICIOUS_USER({ state, dispatch }, data) {
      return axios.post(`${baseURL}scams/update`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          dispatch('CHANGE_SUSPICIOUS', res.data.suspicious)
        }

        return res.data;
      })
    },

    CHANGE_SUSPICIOUS({ commit, state }, data) {
      if(data.suspicious) {
        commit('SET_SUSPICIOUS_TO_STATE',
          state.suspicious.find(v => v.id.toString() === data.suspicious.id.toString()) ? state.suspicious.map(v => v.id.toString() === data.suspicious.id.toString() ? ({ ...v, ...data.suspicious }) : v) : [...state.suspicious, data.suspicious]
        )
      }
    },

    CHANGE_CODE({ commit, state }, data) {
      if(data && data.id) {
        commit('SET_CODES_TO_STATE', {
          ...state.codes,
          all: state.codes.all.find(v => v.id.toString() == data.code.id.toString()) ? state.codes.all.map(v => v.id.toString() == data.code.id.toString() ? ({ ...v, ...data.code }) : v) : [...state.codes.all, data.code],
        })
      }
    },
    GENERATE_CODE({ state }, data) {
      if(data && data.success) {

        if(state.user && state.user.id === data.user && state.$notify) {
          state.$notify({
            id: 'updateCodesConfig',
            type: "success",
            duration: data.generate ? 5000 : 15000,
            title: state.$t('Errors.success_operation'),
            text: data.generate ? state.$t('CodesText.codes_generated_wait_qr') : state.$t('CodesText.codes_generated'),
          });
        }

      }
    },
    GENERATE_CODE_QR({ state }, data) {
      if(data && data.success) {

        if(state.user && state.user.id === data.user && state.$notify) {
          state.$notify({
            id: 'generateQRCodes',
            type: "success",
            duration: 15000,
            title: state.$t('Errors.success_operation'),
            text: state.$t('CodesText.qr_codes_generated'),
          });
        }

      }
    },

    CHANGE_USER({ commit, state, dispatch }, data) {
      commit('SET_EMPLOYEES_TO_STATE', {
        ...state.employees,
        all: state.employees.all.find(v => v.id.toString() === data.user.id.toString()) ? state.employees.all.map(v => v.id.toString() === data.user.id.toString() ? ({ ...v, ...data.user }) : v) : [...state.employees.all, data.user],
      })

      if(state.user && data.user.id === state.user.id) {
        //commit('SET_USER_TO_STATE', ({ ...data.user, token: state.user.token }))
        dispatch('GET_USER_CURRENT_INFO', state.user.token)

        if(state.$notify) {
          if(data.user.active) {
            state.$notify({
              type: "success",
              title: state.$t('EmployeeText.your_profile_updated'),
              text: state.$t('EmployeeText.user_updated'),
            });
          }
          else {
            // notify what user is blocked
            state.$notify({
              type: "error",
              title: state.$t('EmployeeText.your_profile_updated'),
              text: state.$t('LoginText.error_account_inactive'),
            });

            // exit from account
            commit('SET_USER_TO_STATE', null)
          }
        }
      }
    },
    CHANGE_COMPANY({ commit, state }, data) {
      commit('SET_COMPANIES_TO_STATE',
        state.companies.find(v => v.id.toString() === data.company.id.toString()) ? state.companies.map(v => v.id.toString() === data.company.id.toString() ? ({ ...v, ...data.company }) : v) : [...state.companies, data.company],
      )
    },
    CHANGE_COMPANY_USER({ dispatch, state }, data) {
      dispatch('GET_USERS_FROM')
      dispatch('GET_COMPANIES_FROM')
      dispatch('GET_USER_CURRENT_INFO', state.user.token)

      if(data && data.company_user && data.company_user.user === state.user.id) {
        if(state.$notify) {
          state.$notify({
            type: "success",
            title: state.$t('EmployeeText.your_profile_updated'),
            text: state.$t('EmployeeText.access_changed'),
          });
        }
      }
    },

    TAKE_PROMOTION_AS_MANAGER({ commit, state }, data) {
      return axios.post(`${baseURL}promotions/take`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_PROMOTIONS_TO_STATE', {
            ...state.promotions,
            all: state.promotions.all.map(v => v.id.toString() == res.data.promotion.id.toString() ? ({ ...v, ...res.data.promotion }) : v),
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    SET_PROMOTION_EXECUTOR({ commit, state }, data) {
      return axios.post(`${baseURL}promotions/set-executor`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_PROMOTIONS_TO_STATE', {
            ...state.promotions,
            all: state.promotions.all.map(v => v.id.toString() == res.data.promotion.id.toString() ? ({ ...v, ...res.data.promotion }) : v),
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    REMOVE_PROMOTION_EXECUTOR({ commit, state }, data) {
      return axios.post(`${baseURL}promotions/remove-executor`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_PROMOTIONS_TO_STATE', {
            ...state.promotions,
            all: state.promotions.all.map(v => v.id.toString() == res.data.promotion.id.toString() ? ({ ...v, ...res.data.promotion }) : v),
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    SET_PROMOTION_COMPANY({ commit, state }, data) {
      return axios.post(`${baseURL}promotions/set-questionnaire`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_PROMOTIONS_TO_STATE', {
            ...state.promotions,
            all: state.promotions.all.map(v => v.id.toString() == res.data.promotion.id.toString() ? ({ ...v, ...res.data.promotion }) : v),
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    CANCEL_PROMOTION({ commit, state }, data) {
      return axios.post(`${baseURL}promotions/cancel`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_PROMOTIONS_TO_STATE', {
            ...state.promotions,
            all: state.promotions.all.map(v => v.id.toString() == res.data.promotion.id.toString() ? ({ ...v, ...res.data.promotion }) : v),
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    CHECK_PROMOTION({ commit, state }, data) {
      return axios.post(`${baseURL}promotions/check`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_PROMOTIONS_TO_STATE', {
            ...state.promotions,
            all: state.promotions.all.map(v => v.id.toString() == res.data.promotion.id.toString() ? ({ ...v, ...res.data.promotion }) : v),
          })
        }
        return res.data;
      }).catch(err => {
        return err;
      })
    },


    // USER PART
    GET_USERS_FROM({ commit, state }) {
      //return axios.get(`${baseURL}users/all?role=admin`, {
      return axios.get(`${baseURL}users/all`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_EMPLOYEES_TO_STATE', {
            all: res.data.users,
            filter: state.employees.filter
          })
        } else {
          commit('SET_EMPLOYEES_TO_STATE', {
            all: [],
            filter: state.employees.filter
          })
        }
        return res.data;
      }).catch(err => {
        commit('SET_EMPLOYEES_TO_STATE', {
          all: [],
          filter: state.employees.filter
        })
        return err;
      })
    },
    GET_USER_FROM({ state }, data) {
      return axios.get(`${baseURL}users/get?id=${data.id}`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        return res.data;
      }).catch(err => {
        return err;
      })
    },
    CREATE_USER({ dispatch, state }, data) {
      return axios.post(`${baseURL}users/create`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {

        if (response.data.success) {
          if (data.avatar) {
            dispatch('CHANGE_AVATAR', {
              id: response.data.id,
              avatar: data.avatar,
            }).then((avatarRes) => {
              if(avatarRes.success) {
                if (data.role === UsersType.PARTICIPANT)
                  dispatch('GET_PARTICIPANTS_FROM', data)
                else
                  dispatch('GET_USERS_FROM')
              }
            })
          } else {
            if (data.role === UsersType.PARTICIPANT)
              dispatch('GET_PARTICIPANTS_FROM', data)
            else
              dispatch('GET_USERS_FROM')
          }
        }

        return response.data;
      })
    },
    UPDATE_USER({ commit, dispatch, state }, data) {
      return axios.post(`${baseURL}users/update-profile`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {

        if (response.data.success) {

          if (response.data.user.role !== UsersType.PARTICIPANT)
            commit('SET_EMPLOYEES_TO_STATE', {
              all: state.employees.all.map(v => v.id === response.data.user.id ? response.data.user : v),
              filter: state.employees.filter
            })
          else
            commit('SET_PARTICIPANTS_TO_STATE', {
              all: state.participants.all.map(v => v.id === response.data.user.id ? response.data.user : v),
              filter: state.participants.filter
            })

          if (data.avatar) {
            dispatch('CHANGE_AVATAR', {
              id: data.id,
              avatar: data.avatar,
            }).then((avatarRes) => {
              if(avatarRes.success) {
                if (data.role === UsersType.PARTICIPANT)
                  dispatch('GET_PARTICIPANTS_FROM', data)
                else
                  dispatch('GET_USERS_FROM')
              }
            })
          }
        }

        return response.data;
      })
    },
    DEACTIVATE_USER({ commit, state, dispatch }, data) {
      return axios.post(`${baseURL}users/deactivate`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(deactivateUser => {
        if (deactivateUser.data.success) {

          if (deactivateUser.data.user.role !== UsersType.PARTICIPANT)
            commit('SET_EMPLOYEES_TO_STATE', {
              all: state.employees.all.map(v => v.id === deactivateUser.data.user.id ? deactivateUser.data.user : v),
              filter: state.employees.filter
            })
          else
            commit('SET_PARTICIPANTS_TO_STATE', {
              all: state.participants.all.map(v => v.id === deactivateUser.data.user.id ? deactivateUser.data.user : v),
              filter: state.participants.filter
            })

          // get again suspicious users
          dispatch('GET_SUSPICIOUS_FROM', {
            company: state.employees.filter ? state.employees.filter.company : null
          })
        }

        return deactivateUser.data;
      })
    },
    ACTIVATE_USER({ commit, state, dispatch }, data) {
      return axios.post(`${baseURL}users/activate`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(activateUser => {
        if (activateUser.data.success) {
          if (activateUser.data.user.role !== UsersType.PARTICIPANT)
            commit('SET_EMPLOYEES_TO_STATE', {
              all: state.employees.all.map(v => v.id === activateUser.data.user.id ? activateUser.data.user : v),
              filter: state.employees.filter
            })
          else
            commit('SET_PARTICIPANTS_TO_STATE', {
              all: state.participants.all.map(v => v.id === activateUser.data.user.id ? activateUser.data.user : v),
              filter: state.participants.filter
            })

          // get again suspicious users
          dispatch('GET_SUSPICIOUS_FROM', {
            company: state.employees.filter ? state.employees.filter.company : null
          })
        }

        return activateUser.data;
      })
    },
    // eslint-disable-next-line no-empty-pattern
    CHANGE_AVATAR({ state }, data) {
      return axios.post(`${baseURL}users/change-avatar?id=${data.id}`, data.avatar, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(changeAvatarRes => {
        return changeAvatarRes.data;
      })
    },
    // eslint-disable-next-line no-empty-pattern
    UPLOAD_USER_SCAN({ state }, data) {
      return axios.post(`${baseURL}users/upload-scan?id=${data.id}`, data.scan, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {
        return response.data;
      });
    },
    // eslint-disable-next-line no-unused-vars
    SEND_USER_MESSAGE({ commit, state }, data) {
      return axios.post(`${baseURL}users/send-message`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {

          // TODO temporary comment this part - message come from POOL request and update info on all active users
          // commit('SET_EMPLOYEES_TO_STATE', {
          //   ...state.employees,
          //   all: state.employees.all.map(v => v.id.toString() === res.data.user.toString() ? ({ ...v, messages: [...v.messages, res.data.message] }) : v),
          // })
        }

        return res.data;
      }).catch(err => {
        return err;
      })
    },
    // eslint-disable-next-line no-empty-pattern
    GET_USERS_DOWNLOAD({ state }, data) {
      return axios.get(`${baseURL}users/download?format=${data.format}&company=${data.company}`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Учётные записи ${parseFullTime(new Date())}.${data.format === 'xml' ? 'xml' : 'xls'}`
        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    UPDATE_PARTICIPANT({ state }, data) {
      return axios.post(`${baseURL}participants/update`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {
        return response.data;
      })
    },
    CREATE_PARTICIPANT_WINNER({ state }, data) {
      return axios.post(`${baseURL}participants/prizes/create`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {
        return response.data;
      })
    },
    UPDATE_PARTICIPANT_CONFIRM_PRIZE({ state }, data) {
      return axios.post(`${baseURL}participants/prizes/confirm`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {
        return response.data;
      })
    },
    UPDATE_PRIZE_CONFIRM_TAX({ state }, data) {
      return axios.post(`${baseURL}participants/prizes/tax`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {
        return response.data;
      })
    },
    UPDATE_PARTICIPANT_BILL({ state }, data) {
      return axios.post(`${baseURL}participants/bill/update`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {
        return response.data;
      })
    },
    // eslint-disable-next-line no-empty-pattern
    GET_CODES_DOWNLOAD({ state }, data) {
      if(data.format === 'zip') {
        return axios.get(`${baseURL}codes/download?format=${data.format}&promotion=${data.promotion}`, {
          headers: {
            'Authorization': `Bearer ${state.user ? state.user.token : ''}`
          },
        }).then(async res => {
          if(res.data.success) {

            for (var i = 0; i < res.data.files.length; i++) {
              await delay(5000);
              let file = res.data.files[i];
              let link = document.createElement('a');
              link.href = `${baseURL}${file}`;
              link.download = `Коды ${data.promotionName} ${parseFullTime(new Date())}.${data.format}`;
              link.click()
            }

            return { success: true };
          }
          else {
            return { success: false };
          }
        }).catch(err => {
          return err;
        })
      }
      else {
        return axios.get(`${baseURL}codes/download?format=${data.format}&promotion=${data.promotion}`, {
          headers: {
            'Authorization': `Bearer ${state.user ? state.user.token : ''}`
          },
          responseType: 'blob',
        }).then(res => {
          let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `Коды ${data.promotionName} ${parseFullTime(new Date())}.${data.format}`
          link.click()

          return { success: true };
        }).catch(err => {
          return err;
        })
      }
    },


    // COMPANY PART
    GET_COMPANIES_FROM({ state, commit }) {
      return axios.get(`${baseURL}companies/all`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        if (res.data.success) {
          commit('SET_COMPANIES_TO_STATE', res.data.companies)
        } else {
          commit('SET_COMPANIES_TO_STATE', [])
        }
        return res.data;
      }).catch(err => {
        commit('SET_COMPANIES_TO_STATE', [])
        return err;
      })
    },
    CREATE_COMPANY({ dispatch, state }, data) {
      return axios.post(`${baseURL}companies/create`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {

        if (response.data.success) {
          if (data.avatar) {
            dispatch('CHANGE_COMPANY_AVATAR', {
              id: response.data.id,
              avatar: data.avatar,
            }).then((avatarRes) => {
              if(avatarRes.success)
                dispatch('GET_COMPANIES_FROM')
            })
          } else {
            dispatch('GET_COMPANIES_FROM')
          }
        }

        return response.data;
      })
    },
    UPDATE_COMPANY({ dispatch, commit, state }, data) {
      return axios.post(`${baseURL}companies/update`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {

        if (res.data.success) {
          commit('SET_COMPANIES_TO_STATE', state.companies.map(v => v.id === res.data.company.id ? res.data.company : v))

          if (data.avatar) {
            dispatch('CHANGE_COMPANY_AVATAR', {
              id: data.id,
              avatar: data.avatar,
            }).then((avatarRes) => {
              if(avatarRes.success)
                dispatch('GET_COMPANIES_FROM')
            })
          }
        }

        return res.data;
      }).catch(err => {
        return err;
      })
    },
    // eslint-disable-next-line no-empty-pattern
    CHANGE_COMPANY_AVATAR({ state }, data) {
      return axios.post(`${baseURL}companies/change-avatar?id=${data.id}`, data.avatar, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(changeAvatarRes => {
        return changeAvatarRes.data;
      })
    },
    // eslint-disable-next-line no-unused-vars
    SEND_COMPANY_MESSAGE({ commit, state }, data) {
      return axios.post(`${baseURL}companies/send-message`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {

        // TODO temporary comment this part - message come from POOL request and update info on all active users
        // if (res.data.success) {
          // commit('SET_EMPLOYEES_TO_STATE', {
          //   ...state.employees,
          //   all: state.employees.all.map(v => v.id.toString() === res.data.user.toString() ? ({ ...v, messages: [...v.messages, res.data.message] }) : v),
          // })
        // }

        return res.data;
      }).catch(err => {
        return err;
      })
    },
    // eslint-disable-next-line no-empty-pattern
    GET_COMPANIES_DOWNLOAD({ state }, data) {
      return axios.get(`${baseURL}companies/download?format=${data.format}&company=${data.company}`, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Компании ${parseFullTime(new Date())}.${data.format === 'xml' ? 'xml' : 'xls'}`
        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },


    // COMPANY USERS PART
    CREATE_COMPANY_USER({ dispatch, state }, data) {
      return axios.post(`${baseURL}company_users/create`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {

        if (response.data.success) {
          dispatch('GET_USERS_FROM')
          dispatch('GET_COMPANIES_FROM')
        }

        return response.data;
      })
    },
    UPDATE_COMPANY_USER({ dispatch, state }, data) {
      return axios.post(`${baseURL}company_users/update`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {

        if (response.data.success) {
          dispatch('GET_USERS_FROM')
          dispatch('GET_COMPANIES_FROM')
        }

        return response.data;
      })
    },
    DELETE_COMPANY_USER({ dispatch, state }, data) {
      return axios.post(`${baseURL}company_users/delete`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(response => {

        if (response.data.success) {
          dispatch('GET_USERS_FROM')
          dispatch('GET_COMPANIES_FROM')
        }

        return response.data;
      })
    },
    GET_BILL({ state }, data) {
      return axios.get(`${baseURL}participants/bill`, {
        params: data,
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        return res.data;
      }).catch(err => {
        return err;
      })
    },


    // DRAWINGS PART
    GET_DRAWINGS_FROM({ state }, data) {
      return axios.get(`${baseURL}drawings/all`,{
        params: data,
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        // if (res.data.success) {
        //   commit('SET_DRAWINGS_TO_STATE', res.data.drawings)
        // } else {
        //   commit('SET_DRAWINGS_TO_STATE', [])
        // }
        return res.data;
      }).catch(err => {
        console.log('GET_DRAWINGS_FROM: catch', err)

        // commit('SET_DRAWINGS_TO_STATE', [])
        return err;
      })
    },
    GET_DRAWINGS_REPORT({ state }, data) {
      return axios.post(`${baseURL}drawings/download`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        },
        responseType: 'blob',
      }).then(res => {
        let blob = new Blob([res.data], { type: data.format === 'xml' ? 'application/xml' : 'application/vnd.ms-excel' });

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Розыгрыши: ${data.name} ${parseFullTime(data.date)}.${data.format}`

        link.click()

        return { success: true };
      }).catch(err => {
        return err;
      })
    },
    CHANGE_DRAWING({ dispatch, state }, data) {
      if(data && data.drawing) {
        dispatch('GET_DRAWINGS_FROM', {
          company: state.employees ? state.employees.filter ? state.employees.filter.company ? state.employees.filter.company : null : null : null
        })
      }
    },

    TEST_EMAIL_ACCOUNT({ state }, data) {
      return axios.post(`${baseURL}promotions/test/email`, data, {
        headers: {
          'Authorization': `Bearer ${state.user ? state.user.token : ''}`
        }
      }).then(res => {
        return res.data;
      }).catch(err => {
        return err;
      })
    },

  },
  getters: {
    GET_USER(state) {
      return state.user;
    },
    GET_EMPLOYEES(state) {
      return state.employees;
    },
    GET_COMPANIES(state) {
      return state.companies ? state.companies : [];
    },
    GET_PROMOTION_PARTICIPANTS(state) {
      return state.promotionParticipants;
    },
    GET_REPORTS(state) {
      return state.reports ? state.reports : [];
    },
    GET_PROMOTIONS(state) {
      return state.promotions;
    },
    GET_PROMOTIONS_DATA(state) {
      return state.promotions ? state.promotions.all ? state.promotions.all : [] : [];
    },
    GET_CODES(state) {
      return state.codes;
    },
    GET_LISTS(state) {
      return state.lists;
    },
    GET_LIST_ITEMS(state) {
      return state.list_items;
    },
    GET_LIST_GAMES(state) {
      return state.list_items ? state.list_items.games ? state.list_items.games : [] : [];
    },
    GET_LIST_PHONES(state) {
      return state.list_phones;
    },
    GET_STATISTICS(state) {
      return state.statistics;
    },
    GET_PARTICIPANTS(state) {
      return state.participants;
    },
    GET_NOTIFICATIONS(state) {
      return state.notifications;
    },
    GET_SIDE_BAR_STAGE_FUNCTION(state) {
      return state.sideBarRolledUp;
    },
    GET_TIPS(state) {
      return state.tips;
    },
    GET_SUSPICIOUS(state) {
      return state.suspicious;
    },
    GET_DRAWINGS(state) {
      return state.drawings ? state.drawings : [];
    },
  }

})
