import store from '@/store'
import { generateUid } from '@/tools/cryptoTools'
import { hasContent, isEmpty } from "@/tools/dataTools";
import { getMilliseconds } from '@/tools/dateTools'
import { PrizeGameItemRequiredVerify } from '@/models/prizeGames'

export const PrizesType = {
    OBJECT: 'object',
    MONEY: 'money',
    CERTIFICATE: 'certificate',
    OTHERS: 'others',
};

export const PrizesSendType = {
    CONFIRMATION: 'confirmation',
    AUTOMATE: 'automate',
};

export const Action = {
    YES: 'yes',
    NO: 'no',
};

export const ActionForPrize = {
    NO: 'no',
    ACTIVATE_PROMO: 'activatePromo',
};

export const PaymentDirection = {
    NOTHING: 'nothing',
    CHECKING_ACCOUNT: 'checkingAccount',
};

export const DrawingType = {
    EVERYDAY: 'everyday',
    SPECIFIC_DATES: 'specific_dates',
};

export const CertificateStatus = {
    NEW: 'new',
    WAIT: 'wait',
    SENT: 'sent',
};

export const TaxStatus = {
    ALL: 'all',
    PAID: 'paid',
    NONPAID: 'nonpaid',
};

export const Prizes = {
    // UUID
    id: '',
    // string
    full_name: '',
    // string
    name: '',
    // string
    category: '',
    // integer
    categoryNumber: 1,
    // string
    type: PrizesType.OBJECT,
    // float
    price: 0,
    // integer
    pointsQuantity: 1,
    // integer
    count: 0,
    // integer
    prizesQuantityPerPersonPerPromo: 1,
    // integer
    prizesQuantityPerPersonPerDay: 1,
    // integer
    prizesQuantityPerPersonPerWeek: 1,
    // integer
    prizesQuantityPerPersonPerMonth: 1,
    // integer
    awardingPrizeTimeInDays: null,
    // integer
    applicationsQuantityPerPromo: 0,
    // integer
    applicationsQuantityPerDay: 0,
    // integer
    applicationsQuantityPerWeek: 0,
    // integer
    applicationsQuantityPerMonth: 0,
    // string
    automaticalDelivery: Action.NO,
    // string
    automaticalApplicatonForPromo: Action.YES,

    // Drawing part
    drawingType: DrawingType.EVERYDAY,

    // json
    drawingEveryday: {
        // date
        applicatonForPromoStartedAt: null,
        // date
        applicatonForPromoEndedAt: null,
        // date
        drawingPromoStartedAt: null,
        // date
        drawingPromoEndedAt: null,
        // integer
        numberOfPrizes: 1,
        // bool
        moveRemainingPrizesToNextDrawing: false,
        // bool
        finished: false,
    },

    // json
    drawingSpecificDates: [],

    // json
    games: [],

    // string
    deletePointsAfterDelivery: Action.NO,

    // string
    actionForPrize: ActionForPrize.NO,
    // string
    winnerDetermining: null,
    // string
    advancedPrize: Action.NO,
    // string
    actionsForAdvancePrize: ActionForPrize.NO,
    // string
    paymentDirection: PaymentDirection.NOTHING,

    // float
    comission: 0,

    // string
    goodsGroup: null,
    // integer
    goodsGroupScore: null,

    // string
    send_type: PrizesSendType.CONFIRMATION,
    // boolean
    need_confirm_by_manager: true,
    // json
    history: [],

    // boolean
    useOnlineCertificates: false,

    // integer
    certificates: 0,
};

export const NewPrize = () => {
    let item = JSON.parse(JSON.stringify(Prizes))

    item.id = generateUid()

    return item
};

export const PrizesFromBody = (data) => {
    let object = NewPrize()

    object.id = data.id;
    object.full_name = data.full_name;
    object.name = data.name;
    object.category = data.category;
    object.categoryNumber = data.categoryNumber;
    object.type = data.type;
    object.price = data.price;
    object.pointsQuantity = data.pointsQuantity;
    object.count = data.count;

    object.prizesQuantityPerPersonPerPromo = data.prizesQuantityPerPersonPerPromo;
    object.prizesQuantityPerPersonPerDay = data.prizesQuantityPerPersonPerDay;
    object.prizesQuantityPerPersonPerWeek = data.prizesQuantityPerPersonPerWeek;
    object.prizesQuantityPerPersonPerMonth = data.prizesQuantityPerPersonPerMonth;
    object.awardingPrizeTimeInDays = data.awardingPrizeTimeInDays;
    object.applicationsQuantityPerPromo = data.applicationsQuantityPerPromo;
    object.applicationsQuantityPerDay = data.applicationsQuantityPerDay;
    object.applicationsQuantityPerWeek = data.applicationsQuantityPerWeek;
    object.applicationsQuantityPerMonth = data.applicationsQuantityPerMonth;
    object.automaticalDelivery = data.automaticalDelivery;
    object.automaticalApplicatonForPromo = data.automaticalApplicatonForPromo;

    object.drawingType = data.drawingType;
    object.drawingSpecificDates = data.drawingSpecificDates
        ? JSON.parse(JSON.stringify(data.drawingSpecificDates))
        : []
    object.drawingEveryday = data.drawingEveryday ? data.drawingEveryday : {
        // date
        applicatonForPromoStartedAt: null,
        // date
        applicatonForPromoEndedAt: null,
        // date
        drawingPromoStartedAt: null,
        // date
        drawingPromoEndedAt: null,
        // integer
        numberOfPrizes: 1,
        // bool
        moveRemainingPrizesToNextDrawing: false,
        // bool
        finished: false,
    };

    object.games = data.games
        ? JSON.parse(JSON.stringify(data.games))
        : []

    object.deletePointsAfterDelivery = data.deletePointsAfterDelivery;

    object.actionForPrize = data.actionForPrize;
    object.winnerDetermining = data.winnerDetermining;
    object.advancedPrize = data.advancedPrize;
    object.actionsForAdvancePrize = data.actionsForAdvancePrize;
    object.paymentDirection = data.paymentDirection;

    object.comission = data.comission;

    object.goodsGroup = data.goodsGroup;
    object.goodsGroupScore = data.goodsGroupScore;

    object.send_type = data.send_type;
    object.need_confirm_by_manager = data.need_confirm_by_manager;

    object.history = data.history;

    object.useOnlineCertificates = data.useOnlineCertificates ? data.useOnlineCertificates : false;
    object.certificates = data.certificates ? data.certificates : 0;

    return object;
};

export const PrizeRequiredVerify = (prize) => {
    let response = {
        success: true,
        datesSuccess: true,
        certificatesSuccess: true,
        gamesNoDuplicates: true,
    }

    if(hasContent(prize)) {
        if(isEmpty(prize.full_name))
            response.success = false
        else if(isEmpty(prize.name))
            response.success = false
        else if(isEmpty(prize.categoryNumber))
            response.success = false
        else if(isEmpty(prize.category))
            response.success = false
        else if(isEmpty(prize.type))
            response.success = false
        else if(isEmpty(prize.price))
            response.success = false
        else if(isEmpty(prize.count))
            response.success = false
        else if(prize.useOnlineCertificates && (
            !prize.certificates || prize.certificates.length < prize.count
        )) {
            response.certificatesSuccess = false
            response.success = false
        }
        else if(isEmpty(prize.awardingPrizeTimeInDays))
            response.success = false
        else if(isEmpty(prize.automaticalDelivery))
            response.success = false
            // was deleted
            // else if(isEmpty(prize.automaticalApplicatonForPromo))
        //   success = false
        else if(isEmpty(prize.deletePointsAfterDelivery))
            response.success = false
        else if(isEmpty(prize.actionForPrize))
            response.success = false
        else if(isEmpty(prize.actionsForAdvancePrize))
            response.success = false
        else if(isEmpty(prize.paymentDirection))
            response.success = false
            // else if(isEmpty(prize.goodsGroup))
        //   success = false
        else if(isEmpty(prize.winnerDetermining))
            response.success = false
        else if(isEmpty(prize.drawingType))
            response.success = false
        else if(prize.drawingType === DrawingType.EVERYDAY) {
            if (
                isEmpty(prize.drawingEveryday.applicatonForPromoStartedAt) ||
                isEmpty(prize.drawingEveryday.applicatonForPromoEndedAt) ||
                isEmpty(prize.drawingEveryday.drawingPromoStartedAt) ||
                isEmpty(prize.drawingEveryday.drawingPromoEndedAt) ||
                isEmpty(prize.drawingEveryday.numberOfPrizes) ||
                isEmpty(prize.drawingEveryday.moveRemainingPrizesToNextDrawing)
            ) {
                response.success = false
            }
            // compare dates
            else if(getMilliseconds(prize.drawingEveryday.applicatonForPromoEndedAt) < getMilliseconds(prize.drawingEveryday.applicatonForPromoStartedAt)) {
                if(getMilliseconds(prize.drawingEveryday.drawingPromoEndedAt) < getMilliseconds(prize.drawingEveryday.drawingPromoStartedAt)) {
                    response.datesSuccess = false
                }
            }
        }
        else if(prize.drawingType === DrawingType.SPECIFIC_DATES) {
            if(!prize.drawingSpecificDates || prize.drawingSpecificDates.length === 0)
                response.success = false
            else {
                for (let i = 0; i < prize.drawingSpecificDates.length; i++) {
                    let item = prize.drawingSpecificDates[i]

                    // check data competed
                    if (
                        isEmpty(item.applicatonForPromoStartedAt) ||
                        isEmpty(item.applicatonForPromoEndedAt) ||
                        isEmpty(item.drawingPromoAt) ||
                        isEmpty(item.numberOfPrizes) ||
                        isEmpty(item.moveRemainingPrizesToNextDrawing)
                    ) {
                        response.success = false
                    }
                    // compare dates
                    else if(getMilliseconds(item.applicatonForPromoEndedAt) < getMilliseconds(item.applicatonForPromoStartedAt)) {
                        response.datesSuccess = false
                    }
                }
            }
        }

        // check game data
        if (prize.games) {

            // check all games required data is entered
            for (let i = 0; i < prize.games.length; i++) {
                let item = prize.games[i]
                if (!PrizeGameItemRequiredVerify(item)) {
                    response.success = false
                }
            }

            // check the games duplicates
            if (response.success) {
                const valueArr = prize.games.map(function(item){ return item.name });
                const isDuplicate = valueArr.some(function(item, idx){
                    return valueArr.indexOf(item) != idx
                });

                response.gamesNoDuplicates = !isDuplicate;
            }
        }
    }
    else {
        response.success = false
    }

    return response;
};


export const PrizeItem = {
    // UUID
    id: '',
    // JSON
    info: {
        // string
        name: '',
        // string
        category: '',
        // string
        type: PrizesType.OBJECT,
        // float
        price: 0,
        // float
        comission: 0,
    },
    // json
    history: [],
};

export const NewPrizeItem = () => {
    let item = JSON.parse(JSON.stringify(PrizeItem))

    return item
};

export const PrizeItemFromBody = (data) => {
    let object = NewPrizeItem()

    object.id = data.id;
    object.info = JSON.parse(JSON.stringify(data.info));
    object.history = data.history;

    return object;
};


export const PrizesTypes = () => {
    return [
        {
            type: PrizesType.OBJECT,
            label: store.state.$t('PrizesType.object'),
        },
        {
            type: PrizesType.MONEY,
            label: store.state.$t('PrizesType.money'),
        },
        {
            type: PrizesType.CERTIFICATE,
            label: store.state.$t('PrizesType.certificate'),
        },
        {
            type: PrizesType.OTHERS,
            label: store.state.$t('PrizesType.others'),
        },
    ]
};

export const PrizesTypeText = (type) => {
    let prizeType = PrizesTypes().find(item => item.type === type)
    if(type == null || prizeType == null)
        return store.state.$t('PrizesType.unknown');
    else
        return prizeType.label;
};


export const Actions = () => {
    return [
        {
            action: Action.YES,
            label: store.state.$t('Action.yes'),
        },
        {
            action: Action.NO,
            label: store.state.$t('Action.no'),
        },
    ]
};

export const ActionText = (action) => {
    let actionLabel = PrizesTypes().find(item => item.action === action)
    if(action == null || actionLabel == null)
        return store.state.$t('Action.unknown');
    else
        return actionLabel.label;
};


export const ActionsForPrize = () => {
    return [
        {
            action: ActionForPrize.NO,
            label: store.state.$t('ActionForPrize.no'),
        },
        // {
        //     action: ActionForPrize.ACTIVATE_PROMO,
        //     label: store.state.$t('ActionForPrize.activatePromo'),
        // },
    ]
};

export const ActionsForPrizeText = (action) => {
    let actionLabel = ActionsForPrize().find(item => item.action === action)
    if(action == null || actionLabel == null)
        return store.state.$t('ActionForPrize.unknown');
    else
        return actionLabel.label;
};


export const PaymentDirections = () => {
    return [
        {
            direction: PaymentDirection.NOTHING,
            label: store.state.$t('PaymentDirection.nothing'),
        },
        {
            direction: PaymentDirection.CHECKING_ACCOUNT,
            label: store.state.$t('PaymentDirection.checkingAccount'),
        },
    ]
};

export const PaymentDirectionText = (direction) => {
    let paymentDirection = PaymentDirections().find(item => item.direction === direction)
    if(direction == null || paymentDirection == null)
        return store.state.$t('PaymentDirection.unknown');
    else
        return paymentDirection.label;
};

export const PrizesSendTypeText = (type) => {
    switch (type) {
        case PrizesSendType.CONFIRMATION:
            return store.state.$t('PrizesSendType.confirmation');
        case PrizesSendType.AUTOMATE:
            return store.state.$t('PrizesSendType.automate');
        default:
            return store.state.$t('PrizesSendType.unknown');
    }
};

export const PrizeItemRequiredVerify = (prize) => {
    if(hasContent(prize)) {
        if(hasContent(prize.id)) {
            return !!(hasContent(prize.info.name) &&
              hasContent(prize.info.type) &&
              hasContent(prize.info.price));
        }
        else {
            return !!(hasContent(prize.info.name) &&
              hasContent(prize.info.type) &&
              hasContent(prize.info.price));
        }
    }
    else {
        return false;
    }
};


export const DrawingTypes = () => {
    return [
        {
            type: DrawingType.EVERYDAY,
            label: store.state.$t('DrawingType.everyday'),
        },
        {
            type: DrawingType.SPECIFIC_DATES,
            label: store.state.$t('DrawingType.specific_dates'),
        },
    ]
};

export const DrawingTypeText = (type) => {
    let label = DrawingTypes().find(item => item.type === type)
    if(type == null || label == null)
        return store.state.$t('DrawingType.unknown');
    else
        return label.label;
};

export const TaxStatuses = () => {
    return [
        {
            status: TaxStatus.ALL,
            label: store.state.$t('TaxStatus.all'),
        },
        {
            status: TaxStatus.NONPAID,
            label: store.state.$t('TaxStatus.nonpaid'),
        },
        {
            status: TaxStatus.PAID,
            label: store.state.$t('TaxStatus.paid'),
        },
    ]
};


// export module
export default {
    PrizesType,
    PrizesTypes,
    PrizesTypeText,

    PrizesSendType,
    PrizesSendTypeText,

    Prizes,
    NewPrize,
    PrizesFromBody,

    PrizeRequiredVerify,

    PrizeItem,
    NewPrizeItem,
    PrizeItemFromBody,

    PrizeItemRequiredVerify,

    Action,
    Actions,
    ActionText,

    ActionForPrize,
    ActionsForPrize,
    ActionsForPrizeText,

    PaymentDirection,
    PaymentDirections,
    PaymentDirectionText,

    DrawingType,
    DrawingTypes,
    DrawingTypeText,

    CertificateStatus,

    TaxStatus,
    TaxStatuses
};
