<template>
  <div class="listitems">
    <ModalEditReportDelivery
        v-if="modalReportDeliveryOpened"
        :editPermission="editPermission"
        :report="currentDeliveryReport"
        @downloadReport="downloadDeliveryReport"
        @close-modal="closeModalDeliveryReport"
    />
    <ModalEditReportMoney
        v-if="modalReportMoneyOpened"
        :editPermission="editPermission"
        :report="currentReportReport"
        @downloadReport="downloadMoneyReport"
        @close-modal="closeModalMoneyReport"
    />
    <ModalEditReportParticipants
        v-if="modalReportParticipantsOpened"
        :editPermission="editPermission"
        :report="currentParticipantsReport"
        @downloadReport="downloadParticipantsReport"
        @close-modal="closeModalParticipantsReport"
    />
    <ModalEditReportPrizes
        v-if="modalReportPrizesOpened"
        :editPermission="editPermission"
        :report="currentPrizesReport"
        @downloadReport="downloadPrizesReport"
        @close-modal="closeModalPrizesReport"
    />
    <ModalEditReportBills
        v-if="modalReportBillsOpened"
        :editPermission="editPermission"
        :report="currentBillsReport"
        @downloadReport="downloadBillsReport"
        @close-modal="closeModalBillsReport"
    />
    <ModalEditReportCodes
        v-if="modalReportCodesOpened"
        :editPermission="editPermission"
        :report="currentCodesReport"
        @downloadReport="downloadCodesReport"
        @close-modal="closeModalCodesReport"
    />

    <div class="page-header">
      <h2 class="page-header__title">
        {{ $t('ReportText.header') }}
      </h2>
      <div class="buttons-block">

        <div v-if="screenOpened === 'delivery'"
             class="page-header__newcode"
             @click="openModalDeliveryReportInformation(null)"
        >
          <p class="page-header__newcode-text">
            {{ $t('ReportText.add') }}
          </p>
        </div>

        <div v-if="screenOpened === 'money'"
             class="page-header__newcode"
             @click="openModalMoneyReportInformation(null)"
        >
          <p class="page-header__newcode-text">
            {{ $t('ReportText.add') }}
          </p>
        </div>

        <div v-if="screenOpened === 'participants'"
             class="page-header__newcode"
             @click="openModalParticipantsReportInformation(null)"
        >
          <p class="page-header__newcode-text">
            {{ $t('ReportText.add') }}
          </p>
        </div>

        <div v-if="screenOpened === 'prizes'"
             class="page-header__newcode"
             @click="openModalPrizesReportInformation(null)"
        >
          <p class="page-header__newcode-text">
            {{ $t('ReportText.add') }}
          </p>
        </div>

        <div v-if="screenOpened === 'bills'"
             class="page-header__newcode"
             @click="openModalBillsReportInformation(null)"
        >
          <p class="page-header__newcode-text">
            {{ $t('ReportText.add') }}
          </p>
        </div>

        <div v-if="screenOpened === 'codes'"
             class="page-header__newcode"
             @click="openModalCodesReportInformation(null)"
        >
          <p class="page-header__newcode-text">
            {{ $t('ReportText.add') }}
          </p>
        </div>

      </div>
    </div>

    <div class="buttons-panel">

      <div class="buttons-panel__delivery"
        @click="deliveryScreenOpen()"
        :class="{ active: screenOpened === 'delivery'}"
      >
        <p class="buttons-panel__delivery-text">
          {{ $t('ReportText.delivery') }}
        </p>
        <div class="buttons-panel__delivery-counter">
          <p>
            {{ deliveryReportsCount }}
          </p>
        </div>
      </div>

      <div class="buttons-panel__delivery"
        @click="moneyScreenOpen()"
        :class="{ active: screenOpened === 'money'}"
      >
        <p class="buttons-panel__delivery-text">
          {{ $t('ReportText.money') }}
        </p>
        <div class="buttons-panel__delivery-counter">
          <p>
            {{ moneyReportsCount }}
          </p>
        </div>
      </div>

      <div class="buttons-panel__delivery"
           @click="participantsScreenOpen()"
           :class="{ active: screenOpened === 'participants'}"
      >
        <p class="buttons-panel__delivery-text">
          {{ $t('ReportText.participants') }}
        </p>
        <div class="buttons-panel__delivery-counter">
          <p>
            {{ participantsReportsCount }}
          </p>
        </div>
      </div>

      <div class="buttons-panel__delivery"
           @click="drawingsScreenOpen()"
           :class="{ active: screenOpened === 'drawings'}"
      >
        <p class="buttons-panel__delivery-text">
          {{ $t('ReportText.drawings') }}
        </p>
        <div class="buttons-panel__delivery-counter">
          <p>
            {{ drawingsReportsCount }}
          </p>
        </div>
      </div>

      <div class="buttons-panel__delivery"
           @click="prizesScreenOpen()"
           :class="{ active: screenOpened === 'prizes'}"
      >
        <p class="buttons-panel__delivery-text">
          {{ $t('ReportText.prizes') }}
        </p>
        <div class="buttons-panel__delivery-counter">
          <p>
            {{ prizesReportsCount }}
          </p>
        </div>
      </div>

      <div class="buttons-panel__delivery"
           @click="billsScreenOpen()"
           :class="{ active: screenOpened === 'bills'}"
      >
        <p class="buttons-panel__delivery-text">
          {{ $t('ReportText.bills') }}
        </p>
        <div class="buttons-panel__delivery-counter">
          <p>
            {{ billsReportsCount }}
          </p>
        </div>
      </div>

      <div class="buttons-panel__delivery"
           @click="codesScreenOpen()"
           :class="{ active: screenOpened === 'codes'}"
      >
        <p class="buttons-panel__delivery-text">
          {{ $t('ReportText.codes') }}
        </p>
        <div class="buttons-panel__delivery-counter">
          <p>
            {{ codesReportsCount }}
          </p>
        </div>
      </div>

      <div class="buttons-panel__input">
        <p class="buttons-panel__input-text">
          {{ $t('InterfaceText.search') }}
        </p>
        <input type="text" class="buttons-panel__input-input" :placeholder="$t('FieldsText.enter_request')"
               :value="filterData.search"
               @input="setListsSearch">
        <div class="buttons-panel__input-button"></div>
      </div>

    </div>

    <ListsReportsDeliveryModal
        v-if="screenOpened === 'delivery'"
        :editPermission="editPermission"
        :search="filterData.search"
        :items="deliveryReports"
        @refresh="getReports"
        @edit="openModalDeliveryReportInformation"
        @download="downloadDeliveryReport"
        @delete="deleteReport"/>

    <ListsReportsMoneyModal
      v-if="screenOpened === 'money'"
      :editPermission="editPermission"
      :search="filterData.search"
      :items="moneyReports"
      @refresh="getReports"
      @edit="openModalMoneyReportInformation"
      @download="downloadMoneyReport"
      @delete="deleteReport"/>

    <ListsReportsParticipantsModal
        v-if="screenOpened === 'participants'"
        :editPermission="editPermission"
        :search="filterData.search"
        :items="participantsReports"
        @refresh="getReports"
        @edit="openModalParticipantsReportInformation"
        @download="downloadParticipantsReport"
        @delete="deleteReport"/>

    <ListsReportsDrawingsModal
        v-if="screenOpened === 'drawings'"
        :editPermission="editPermission"
        :search="filterData.search"
        :items="drawingsReports"
        @refresh="getDrawings"
        @edit="openModalDeliveryReportInformation"
        @download="downloadDrawingsReport"
        @delete="deleteReport"/>

    <ListsReportsPrizesModal
        v-if="screenOpened === 'prizes'"
        :editPermission="editPermission"
        :search="filterData.search"
        :items="prizesReports"
        @refresh="getReports"
        @edit="openModalPrizesReportInformation"
        @download="downloadPrizesReport"
        @delete="deleteReport"/>

    <ListsReportsBillsModal
        v-if="screenOpened === 'bills'"
        :editPermission="editPermission"
        :search="filterData.search"
        :items="billsReports"
        @refresh="getReports"
        @edit="openModalBillsReportInformation"
        @download="downloadBillsReport"
        @delete="deleteReport"/>

    <ListsReportsCodesModal
        v-if="screenOpened === 'codes'"
        :editPermission="editPermission"
        :search="filterData.search"
        :items="codesReports"
        @refresh="getReports"
        @edit="openModalCodesReportInformation"
        @download="downloadCodesReport"
        @delete="deleteReport"/>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import InputsMixin from "@/mixins/inputsEvent";

import ModalEditReportMoney from "@/components/ModalEditReportMoney";
import ModalEditReportDelivery from "@/components/ModalEditReportDelivery";
import ModalEditReportParticipants from "@/components/ModalEditReportParticipants";
import ModalEditReportPrizes from "@/components/ModalEditReportPrizes";
import ModalEditReportBills from "@/components/ModalEditReportBills";

import ListsReportsDeliveryModal from '../components/ListsReportsDeliveryModal'
import ListsReportsMoneyModal from '../components/ListsReportsMoneyModal'
import ListsReportsParticipantsModal from '../components/ListsReportsParticipantsModal'
import ListsReportsDrawingsModal from '../components/ListsReportsDrawingsModal'
import ListsReportsPrizesModal from '../components/ListsReportsPrizesModal'
import ListsReportsBillsModal from '../components/ListsReportsBillsModal'

import {hasContent} from "@/tools/dataTools";
import {UsersType} from "@/models/users";
import {ReportType} from "@/models/reports";
import ModalEditReportCodes from '@/components/ModalEditReportCodes.vue'
import ListsReportsCodesModal from '@/components/ListsReportsCodesModal.vue'

export default {
  name: 'ListItems',
  components: {
    ListsReportsCodesModal,
    ModalEditReportCodes,
    ModalEditReportMoney,
    ModalEditReportDelivery,
    ModalEditReportParticipants,
    ModalEditReportPrizes,
    ModalEditReportBills,

    ListsReportsDeliveryModal,
    ListsReportsMoneyModal,
    ListsReportsParticipantsModal,
    ListsReportsDrawingsModal,
    ListsReportsPrizesModal,
    ListsReportsBillsModal,
  },
  mixins: [InputsMixin],
  data() {
    return {
      isLoading: false,

      modalReportMoneyOpened: false,
      modalReportDeliveryOpened: false,
      modalReportParticipantsOpened: false,
      modalReportDrawingOpened: false,
      modalReportPrizesOpened: false,
      modalReportBillsOpened: false,
      modalReportCodesOpened: false,

      currentReportReport: null,
      currentDeliveryReport: null,
      currentParticipantsReport: null,
      currentDrawingsReport: null,
      currentPrizesReport: null,
      currentBillsReport: null,
      currentCodesReport: null,

      screenOpened: 'delivery', // delivery | money | participants | drawings | prizes | bills | codes

      drawings: [],

      // data
      filterData: {
        search: '',
      },
    }
  },
  beforeMount() {
  },
  mounted() {
    if (this.user?.token) {
      this.deliveryScreenOpen()

      this.getAllData()
    }
    else {
      this.$router.push({ name: "Login" }).catch(() => {});
    }
  },
  computed: {
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN) : false;
    },
    editPermission() {
      return this.isAdmin;
    },
    deliveryReports() {
      return this.GET_REPORTS().filter( (r) => r.type === ReportType.DELIVERY);
    },
    deliveryReportsCount() {
      return this.deliveryReports.length;
    },
    moneyReports() {
      return this.GET_REPORTS().filter( (r) => r.type === ReportType.MONEY);
    },
    moneyReportsCount() {
      return this.moneyReports.length;
    },
    participantsReports() {
      return this.GET_REPORTS().filter( (r) => r.type === ReportType.PARTICIPANTS);
    },
    participantsReportsCount() {
      return this.participantsReports.length;
    },
    drawingsReports() {
      return this.drawings ? this.drawings : [];
    },
    drawingsReportsCount() {
      return this.drawingsReports.length;
    },
    prizesReports() {
      return this.GET_REPORTS().filter( (r) => r.type === ReportType.PRIZES);
    },
    prizesReportsCount() {
      return this.prizesReports.length;
    },
    billsReports() {
      return this.GET_REPORTS().filter( (r) => r.type === ReportType.BILLS);
    },
    billsReportsCount() {
      return this.billsReports.length;
    },
    codesReports() {
      return this.GET_REPORTS().filter( (r) => r.type === ReportType.CODES);
    },
    codesReportsCount() {
      return this.codesReports.length;
    },
    employees_filter() {
      return this.GET_EMPLOYEES().filter;
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    "$store.state.employees.filter": function (newValue, oldValue) {
      console.log("$store.state.employees.filter")

      if(this.user) {
        if(newValue != null) {
          this.filterData.company = newValue.company
        }

        this.getAllData();
      }
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.list_items.filter": function (newValue, oldValue) {
      if(newValue != null) {
        this.filterData.search = newValue.search
      }

      if(newValue.search !== oldValue.search)
        this.filterItems();
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.list_items.delivery": function (newValue, oldValue) {
      this.filterItems();
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  methods: {
    ...mapGetters(["GET_USER","GET_REPORTS","GET_EMPLOYEES"]),
    ...mapActions([
      "GET_DELIVERY_REPORT",
      "GET_REPORTS_FROM",
      "GET_MONEY_REPORT",
      "GET_PARTICIPANTS_REPORT",
      "DELETE_REPORT",
      "GET_DRAWINGS_FROM",
      "GET_DRAWINGS_REPORT",
      "GET_PRIZES_REPORT",
      "GET_BILLS_REPORT",
      "GET_CODES_REPORT",
    ]),

    getAllData() {
      this.getReports()
      this.getDrawings()
    },

    deliveryScreenOpen() {
      this.filterData.search = '';
      this.screenOpened = 'delivery';

      this.getReports()
    },
    moneyScreenOpen() {
      this.filterData.search = '';
      this.screenOpened = 'money';

      this.getReports()
    },
    participantsScreenOpen() {
      this.filterData.search = '';
      this.screenOpened = 'participants';

      this.getReports()
    },
    drawingsScreenOpen() {
      this.filterData.search = '';
      this.screenOpened = 'drawings';

      this.getDrawings()
    },
    prizesScreenOpen() {
      this.filterData.search = '';
      this.screenOpened = 'prizes';

      this.getReports()
    },
    billsScreenOpen() {
      this.filterData.search = '';
      this.screenOpened = 'bills';

      this.getReports()
    },
    codesScreenOpen() {
      this.filterData.search = '';
      this.screenOpened = 'codes';

      this.getReports()
    },

    setListsSearch(e) {
      if(hasContent(e.target.value)) {
        this.filterData.search = e.target.value
        this.filterItems()
      }
      else {
        this.filterData.search = ''
        this.filterItems()
      }
    },

    filterItems() {

    },


    openModalMoneyReportInformation(prize) {
      this.currentReportReport = prize;
      this.modalReportMoneyOpened = true;
    },

    closeModalMoneyReport() {
      this.currentReportReport = null;
      this.modalReportMoneyOpened = false;

      this.getReports()
    },


    openModalDeliveryReportInformation(report) {
      this.currentDeliveryReport = report;
      this.modalReportDeliveryOpened = true;
    },

    closeModalDeliveryReport() {
      this.currentDeliveryReport = null;
      this.modalReportDeliveryOpened = false;

      this.getReports()
    },


    openModalParticipantsReportInformation(report) {
      this.currentParticipantsReport = report;
      this.modalReportParticipantsOpened = true;
    },

    closeModalParticipantsReport() {
      this.currentParticipantsReport = null;
      this.modalReportParticipantsOpened = false;

      this.getReports()
    },


    openModalPrizesReportInformation(report) {
      this.currentPrizesReport = report;
      this.modalReportPrizesOpened = true;
    },

    closeModalPrizesReport() {
      this.currentPrizesReport = null;
      this.modalReportPrizesOpened = false;

      this.getReports()
    },


    openModalBillsReportInformation(report) {
      this.currentBillsReport = report;
      this.modalReportBillsOpened = true;
    },

    closeModalBillsReport() {
      this.currentBillsReport = null;
      this.modalReportBillsOpened = false;

      this.getReports()
    },


    openModalCodesReportInformation(report) {
      this.currentCodesReport = report;
      this.modalReportCodesOpened = true;
    },

    closeModalCodesReport() {
      this.currentCodesReport = null;
      this.modalReportCodesOpened = false;

      this.getReports()
    },

    getReports() {
      if(this.user) {
        this.isLoading = true

        this.GET_REPORTS_FROM({
          company: this.employees_filter ? this.employees_filter.company : null
        }).then(() => {
          this.isLoading = false
        });
      }
    },

    getDrawings() {
      if(this.user) {
        this.isLoading = true

        this.GET_DRAWINGS_FROM({
          company: this.employees_filter ? this.employees_filter.company : null
        }).then((response) => {
          this.isLoading = false

          if (response.success) {
            this.drawings = response.drawings ? response.drawings : []
          }
        });
      }
    },

    downloadList(format) {
      this.downloadDeliveryReport(format)
    },

    // requests
    downloadDeliveryReport(report) {
      if (report) {
        this.isLoading = true
        this.$notify({
          id: 'downloadDeliveryReport',
          duration: 9999999999,
          type: "notification",
          title: this.$t('Errors.wait_finish'),
          text: this.$t('CodesText.download_doc_started'),
        });

        // download specific format
        this.GET_DELIVERY_REPORT({
          name: report.info.name,
          format: 'xls',
          companies: report.info.companies,
          promotions: report.info.promotions,
          from: report.info.from,
          to: report.info.to,
        }).then(() => {
          this.isLoading = false
          this.$notify.close('downloadDeliveryReport');

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('Errors.file_is_generated'),
          });
        }).catch(() => {
          this.isLoading = false

          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.file_is_not_generated'),
          });
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

    deleteReport(report) {
      if(report) {
        if (
            confirm(this.$t('ReportText.delete'))
        ) {
          this.isLoading = true

          this.DELETE_REPORT({
            id: report.id,
          }).then((res) => {
            this.isLoading = false

            if (res.success) {
              this.getReports()

              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('ReportText.delete_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('ReportText.delete_code_error'),
              });
            }
          });
        }
      }
    },

    // requests
    downloadMoneyReport(report) {
      if (report) {
        this.isLoading = true
        this.$notify({
          id: 'downloadMoneyReport',
          duration: 9999999999,
          type: "notification",
          title: this.$t('Errors.wait_finish'),
          text: this.$t('CodesText.download_doc_started'),
        });

        // download specific format
        this.GET_MONEY_REPORT({
          name: report.info.name,
          format: 'xls',
          company: report.info.company,
          promotion: report.info.promotion,
          from: report.info.from,
          to: report.info.to,
        }).then(() => {
          this.isLoading = false
          this.$notify.close('downloadMoneyReport');

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('Errors.file_is_generated'),
          });
        }).catch(() => {
          this.isLoading = false

          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.file_is_not_generated'),
          });
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

    // requests
    downloadParticipantsReport(report) {
      if (report) {
        this.isLoading = true
        this.$notify({
          id: 'downloadParticipantsReport',
          duration: 9999999999,
          type: "notification",
          title: this.$t('Errors.wait_finish'),
          text: this.$t('CodesText.download_doc_started'),
        });

        // download specific format
        this.GET_PARTICIPANTS_REPORT({
          name: report.info.name,
          format: 'xls',
          company: report.info.company,
          promotion: report.info.promotion,
          from: report.info.from,
          to: report.info.to,
        }).then(() => {
          this.isLoading = false
          this.$notify.close('downloadParticipantsReport');

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('Errors.file_is_generated'),
          });
        }).catch(() => {
          this.isLoading = false

          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.file_is_not_generated'),
          });
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

    // requests
    downloadDrawingsReport(report) {
      if (report) {
        this.isLoading = true
        this.$notify({
          id: 'downloadDrawingsReport',
          duration: 9999999999,
          type: "notification",
          title: this.$t('Errors.wait_finish'),
          text: this.$t('CodesText.download_doc_started'),
        });

        // download specific format
        this.GET_DRAWINGS_REPORT({
          id: report.id,
          format: 'xls',
          name: report.prize.full_name,
          date: report.createdAt,
        }).then(() => {
          this.isLoading = false
          this.$notify.close('downloadDrawingsReport');

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('Errors.file_is_generated'),
          });
        }).catch(() => {
          this.isLoading = false

          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.file_is_not_generated'),
          });
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

    // requests
    downloadPrizesReport(report) {
      if (report) {
        this.isLoading = true
        this.$notify({
          id: 'downloadPrizesReport',
          duration: 9999999999,
          type: "notification",
          title: this.$t('Errors.wait_finish'),
          text: this.$t('CodesText.download_doc_started'),
        });

        // download specific format
        this.GET_PRIZES_REPORT({
          name: report.info.name,
          format: 'xls',
          company: report.info.company,
          promotion: report.info.promotion,
          from: report.info.from,
          to: report.info.to,
        }).then(() => {
          this.isLoading = false
          this.$notify.close('downloadPrizesReport');

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('Errors.file_is_generated'),
          });
        }).catch(() => {
          this.isLoading = false

          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.file_is_not_generated'),
          });
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

    // requests
    downloadBillsReport(report) {
      if (report) {
        this.isLoading = true
        this.$notify({
          id: 'downloadBillsReport',
          duration: 9999999999,
          type: "notification",
          title: this.$t('Errors.wait_finish'),
          text: this.$t('CodesText.download_doc_started'),
        });

        // download specific format
        this.GET_BILLS_REPORT({
          name: report.info.name,
          format: 'xls',
          company: report.info.company,
          promotion: report.info.promotion,
          from: report.info.from,
          to: report.info.to,
        }).then(() => {
          this.isLoading = false
          this.$notify.close('downloadBillsReport');

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('Errors.file_is_generated'),
          });
        }).catch(() => {
          this.isLoading = false

          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.file_is_not_generated'),
          });
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

    // requests
    downloadCodesReport(report) {
      if (report) {
        this.isLoading = true
        this.$notify({
          id: 'downloadCodesReport',
          duration: 9999999999,
          type: "notification",
          title: this.$t('Errors.wait_finish'),
          text: this.$t('CodesText.download_doc_started'),
        });

        // download specific format
        this.GET_CODES_REPORT({
          name: report.info.name,
          format: 'xls',
          company: report.info.company,
          promotion: report.info.promotion,
          from: report.info.from,
          to: report.info.to,
        }).then(() => {
          this.isLoading = false
          this.$notify.close('downloadCodesReport');

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('Errors.file_is_generated'),
          });
        }).catch(() => {
          this.isLoading = false

          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.file_is_not_generated'),
          });
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.listitems {
  flex: 1;
  width: 100%;
  min-width: 1150px;

  .buttons-panel {
    margin-bottom: 30px;
  }

  .buttons-panel__checkbox {
    margin-right: 30px;
  }

  .table {
    //@include grid-table-row(60px, 30px, 11% 88px 11% 6px 11% 31px 7.3% 43px 13.5% 18px 10% 23px 10% 69px 3.4%);
    @include grid-table-row(145px, 60px, 11.8% 136px 13.75% 136px 14% 153px 8.2% 120px 7%);

    &__cell {
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 4;
      }
      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 6;
      }
      &:nth-child(4) {
        grid-column-start: 7;
        grid-column-end: 8;
      }
      &:nth-child(5) {
        grid-column-start: 9;
        grid-column-end: 10;
      }
      &:nth-child(6) {
        grid-column-start: 11;
        grid-column-end: 12;
      }
      &:nth-child(7) {
        grid-column-start: 13;
        grid-column-end: 14;
      }
      &:nth-child(8) {
        grid-column-start: 15;
        grid-column-end: 16;
      }
    }
  }

  .table2 {
    @include grid-table-row(60px, 40px, 27% 207px 25.2% 210px 6%);

    &__cell {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 2;
      }
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 4;
      }
      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 6;
      }
    }
  }

}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  &__title {
    @include page-header;
  }
}

.buttons-block {
  display: flex;
}

.page-header__outload {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 180px;
  padding: 7px 0;
  background-color: $white;
  border-radius: 5px;
  border: 1px solid $purple;
  cursor: pointer;
  // border-image: linear-gradient(#8F21C9, #4640B9) 30 / 1 / 0 stretch;
  svg {
    margin-right: 5px;
  }
  img {
   margin-right: 5px;
  }
  &-text {
    font-size: 14px;
    font-weight: 16px;
    color: $purple;
  }
}

.buttons-panel {
  display: flex;
  align-items: center;
  padding: 25px 110px 25px 30px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 2px #CED5F480;
}

.buttons-panel__delivery {
  display: flex;
  align-items: center;
  margin-right: 15px;
  padding-right: 16px;
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: -4.5px;
    right: 0;
    width: 2px;
    height: 37px;
    background-color: $bodyBackground;
  }

  &-text {
    font-size: 16px;
    font-weight: 400;
    margin-right: 7px;
  }

  &-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    min-width: 28px;
    height: 28px;
    min-height: 28px;
    background: $inputCaption;
    border-radius: 50px;
    p {
      color: $white;
    }
  }

  &.active {
    .buttons-panel__delivery-text {
      font-weight: 600;
    }

    .buttons-panel__delivery-counter {
      background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
    }
  }
}

.buttons-panel__input {
  display: flex;
  align-items: center;
  margin-right: 30px;
  &-text {
    font-size: 12px;
    font-weight: 500;
    margin-right: 30px;
  }

  &-input {
    @include search-input;
  }

  &-button {
    @include search-input__button;
  }

  &.select {
    .buttons-panel__input-text {
      position: relative;
      &::after {
        content: "*";
        position: absolute;
        top: -2px;
        right: -6px;
        color: $red;
      }
    }

    .buttons-panel__input-button {
      &::after {
        width: 9px;
        height: 7px;
        background: url('../assets/img/triangle.png');
      }
    }
  }
}

.buttons-panel__checkbox {
  display: flex;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  &-checkbox {
    display: none;
  }
  &-fake {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 12px;
    border-radius: 2px;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    position: relative;
    &::before {
      content: '✔';
      position: absolute;
      color: $black;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }
  &-text {
    font-size: 12px;
    font-weight: 500;
    color: $activeText;
  }
  .buttons-panel__checkbox-checkbox:checked + .buttons-panel__checkbox-fake::before {
    opacity: 1;
  }
  span {
    color: $red;
  }
}

</style>
