<template>
  <div
    class="modal"
    v-if="opened"
  >
    <div class="wrapper">

      <!-- Header -->
      <div class="header">
        <div class="left-content">
          <div class="text">{{ cardName }}</div>
        </div>
        <div
          class="close"
          @click="close"
        >
          <img src="@/assets/img/icons/close.svg">
        </div>

      </div>

      <!-- Content -->
      <div class="content">

        <div class="chat-history">
          <div class="tabs-wrapper">
            <!-- Main Info Tab -->
            <div v-if="mainTabVisibility"
                class="tab-chat"
                @click="activeTab = 0"
            >
              <p>{{ $t('TabHeaders.general') }}</p>
              <div
                  class="shadow"
                  v-if="!(activeTab === 0)"
              ></div>
            </div>
            <!-- Users Info Tab -->
            <div v-if="usersTabVisibility"
                 class="tab-chat"
                 @click="activeTab = 1"
            >
              <p>{{ $t('TabHeaders.users') }}</p>
              <div
                  class="shadow"
                  v-if="!(activeTab === 1)"
              ></div>
            </div>
            <!-- Chat Tab -->
            <div v-if="chatTabVisibility"
                class="tab-chat"
                @click="activeTab = 2; scrollChatToLastPosition();"
            >
              <p>{{ $t('TabHeaders.chat') }}</p>
              <div
                  class="shadow"
                  v-if="!(activeTab === 2)"
              ></div>
            </div>
            <!-- History Tab -->
            <div v-if="historyTabVisibility"
                class="tab-history"
                @click="activeTab = 3"
            >
              <p>{{ $t('TabHeaders.history') }}</p>
              <div
                  class="shadow"
                  v-if="!(activeTab === 3)"
              ></div>
            </div>
          </div>

          <!-- Main Info Tab Content -->
          <div
              class="features main-wrapper"
              v-if="activeTab === 0"
          >

            <div class="new-account">
              <div class="new-account__inner">
                <div class="new-account__columns">

                  <!-- General Info Tab -->
                  <div class="new-account__column">

                    <div class="new-account__inner-header">
                      <p>{{ $t('FieldsText.general_info') }}</p>
                    </div>

                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.company_name_req_label') }}
                      </p>
                      <input class="new-account__item-input" type="text" :placeholder="$t('FieldsText.company_name_label_input')"
                             :disabled="!editPermission"
                             v-model="companyData.name" required>
                    </div>

                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.company_inn_label') }}
                      </p>
                      <input class="new-account__item-input" type="text" name="inn" maxlength="12"
                             oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                             :placeholder="$t('FieldsText.company_inn_label_input')"
                             :disabled="!editPermission"
                             v-model="companyData.inn" required>
                    </div>

                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.company_bik_label') }}
                      </p>
                      <input class="new-account__item-input" type="text"
                             oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                             :placeholder="$t('FieldsText.company_bik_label_input')"
                             :disabled="!editPermission"
                             v-model="companyData.bik">
                    </div>

                    <div style="height: 180px;"></div>

                  </div>

                  <!-- Additional Info Tab -->
                  <div class="new-account__column">

                    <div class="new-account__inner-header">
<!--                      <p>{{ $t('InterfaceText.empty_field') }}</p>-->
                      <p v-html="$t('InterfaceText.empty_field')"></p>
                    </div>

                    <!-- Director -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.company_director_label') }}
                      </p>
                      <input class="new-account__item-input" type="text"
                             oninput="this.value = this.value.replace(/[^a-zA-Zа-яА-Я\s]/g, '')"
                             :placeholder="$t('FieldsText.company_director_label_input')"
                             :disabled="!editPermission"
                             v-model="companyData.director">
                    </div>

                    <!-- COR -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.company_cor_label') }}
                      </p>
                      <input class="new-account__item-input" type="text"
                             oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                             :placeholder="$t('FieldsText.company_cor_label_input')"
                             :disabled="!editPermission"
                             v-model="companyData.cor_count">
                    </div>

                    <!-- Address -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.address_label') }}
                      </p>
                      <input class="new-account__item-input" type="text"
                             oninput="this.value = this.value.replace(/[^a-zA-Zа-яА-Я0-9/\s]/g, '')"
                             :placeholder="$t('FieldsText.address_label_input')"
                             :disabled="!editPermission"
                             v-model="companyData.address">
                    </div>

                  </div>
                </div>

                <div class="new-account__validation-rule">
                  <span>* </span>{{ $t('Rules.required_info') }}
                </div>
                <div class="buttons-block">
                  <div v-if="editPermission" class="buttons-block__save" @click="addCompany">
                    <p class="buttons-block__save-text">
                      {{ saveBtName }}
                    </p>
                  </div>
                  <div
                      class="buttons-block__back"
                      @click="close"
                  >
                    <p class="buttons-block__back-text">
                      {{ $t('Buttons.close') }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="new-account__logo">

                <div class="new-account__inner-header">
                  <p>{{ $t('InterfaceText.company_logo') }}</p>
                </div>

                <div v-if="editPermission" class="new-account__logo-drop pointer"
                     @dragover="dragover"
                     @dragleave="dragleave"
                     @drop="dropAvatar"
                >

                  <img
                      src="@/assets/img/bi_download.svg"
                      alt="notification avatar">

                  <label for="assetsFieldHandle" class="block cursor-pointer pointer">
                    <div class="new-account__logo-text">
                      {{ $t('Photos.drop_logotip_here') }}
                    </div>
                  </label>
                  <input id="assetsFieldHandle"
                         class="file_input pointer"
                         type="file" name="fields[assetsFieldHandle][]"
                         @change="onChangeAvatar" ref="avatar_file" accept=".jpg,.jpeg,.png" />
                </div>

                <!-- View Profile Avatar -->
                <div v-if="avatar && reloadPreview"
                     style="margin-top: 0px; width: 100%;"
                     class="images avatar-preview">
                  <img
                      style="object-fit: inherit;"
                      class="avatar-preview__item"
                      :key="avatar"
                      :src="avatar.url"
                  >
                </div>

              </div>
            </div>

          </div>

          <!-- Users Access Tab Content -->
          <div
              class="features main-wrapper"
              v-if="activeTab === 1"
          >

            <div class="new-account">
              <div class="new-account__inner">

                <!-- Users list -->
                <div class="new-account__column">

                  <vue-table-lite class="tableLite"
                      :is-slot-mode="true"
                      :has-checkbox="table.hasCheckbox"
                      :is-loading="table.isLoading"
                      :is-re-search="table.isReSearch"
                      :is-hide-paging="table.isHidePaging"
                      :columns="table.columns"
                      :rows="table.rows"
                      :total="table.totalRecordCount"
                      :sortable="table.sortable"
                      :messages="table.messages"
                      @do-search="doUserSearch"
                      @is-finished="table.isLoading = false"
                      @return-checked-row="updateCheckedRows"
                  >

                    <template v-slot:fio="data">
                      {{ `${data.value.name}&nbsp;${data.value.surname}&nbsp;${data.value.lastname}` }}
                    </template>

                    <template v-slot:role="data">
                      {{  UserTypeText(data.value.role) }}
                    </template>

                    <template v-slot:read="data">
                      <input
                        :disabled="!editUserPermission"
                        type="checkbox" id="checkboxRead"
                        @input="setUserReadAccess(data.value)"
                        v-model="data.value.companies[0].CompanyUsers.read" />
                    </template>

                    <template v-slot:write="data">
                      <input
                        :disabled="!editUserPermission"
                        type="checkbox" id="checkboxWrite"
                        @input="setUserReadAccess(data.value)"
                        v-model="data.value.companies[0].CompanyUsers.write" />
                    </template>

                  </vue-table-lite>

                  <div style="height: 17px;"></div>

                </div>

                <div class="new-account__validation-rule">
                  <span>* </span>{{ $t('Rules.select_users_access') }}
                </div>
                <div class="buttons-block">
                  <div v-if="editPermission" class="buttons-block__save" @click="saveCompanyUsers">
                    <p class="buttons-block__save-text">
                      {{ $t('Buttons.save') }}
                    </p>
                  </div>
                  <div
                      class="buttons-block__back"
                      @click="close"
                  >
                    <p class="buttons-block__back-text">
                      {{ $t('Buttons.close') }}
                    </p>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <!-- Chat Tab Content -->
          <div
              class="chat-wrapper"
              v-if="activeTab === 2"
          >
            <div
                id='msgsContainer'
                ref='msgsContainer'
                class="msgs-container"
            >
              <div
                  v-for="msg in companyData.messages"
                  :key="msg.date"
                  :class="msg.user_id === user.id ? 'msg-container self-message': 'msg-container'"
              >
                <img
                    class="avatar"
                    :src="getUserAvatarUrl(msg.avatar, msg.user_id === user.id)"
                    alt="avatar icon"
                >
                <div class="msg-wrapper">
                  <div class="msg">
                    <div class="msg-info">
                      <p class="name">{{msg.user}}</p>
                      <p class="date-time">{{parseFullTime(msg.date)}}</p>
                    </div>
                    <p class="text">{{msg.message}}</p>
                  </div>
                  <!--span class="answer-button">Ответить</span-->
                </div>
              </div>
            </div>
            <div class="chat-input">
              <img
                  class="avatar"
                  :src="getUserAvatarUrl(user.avatar, true)"
                  alt="avatar icon"
              >
              <div class="chat-form">
                <input
                    class="chat-message"
                    :placeholder="$t('InterfaceText.write_message')"
                    v-model="message"
                >
                <button
                    :class="(message == null || message === '') ? 'send-message-inactive' : 'send-message'"
                    :disabled="message == null || message === ''"
                    @click="sendMessage"
                >{{ $t('Buttons.send' )}}</button>
              </div>
            </div>
          </div>

          <!-- History Tab Content -->
          <div
              class="history-wrapper"
              v-if="activeTab === 3"
          >
            <div class="inner">
              <div class="table-wrapper">
                <div class="column-names">
                  <div class="date">{{ $t('TableHeaders.date') }}</div>
                  <div class="time">{{ $t('TableHeaders.time') }}</div>
                  <div class="author">{{ $t('TableHeaders.name') }}</div>
                  <div class="changes">{{ $t('TableHeaders.changes') }}</div>
                </div>
                <div class="table">
                  <div
                      class="table-string"
                      v-for="action in companyData.history"
                      :key="action.date"
                  >
                    <div class="table-cell">{{parseDate(action.date)}}</div>
                    <div class="table-cell">{{parseTime(action.date)}}</div>
                    <div class="table-cell">{{action.user}}</div>
                    <div class="table-cell">{{action.status}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import InputsMixin from "@/mixins/inputsEvent";
import DragDrop from "@/mixins/dragDropEvents";

import 'viewerjs/dist/viewer.css'

import {
  NewCompanies,
  CompaniesRequiredVerify,
  CompaniesFromBody,
} from "@/models/companies";

import {
  UsersType,
  UserTypeText,
} from "@/models/users";

import {
  getFileImage, getImageUrl,

  getUserAvatarUrl,
} from "@/tools/imageTools";

import {
  hasContent,
  isEmpty,
} from "@/tools/dataTools";

import {
  parseDate,
  parseTime,
  parseFullTime,
} from "@/tools/dateTools";

import {
  CompanyUsersCompanyIsNewAndEmpty,
  CompanyUsersCompanyIsNew,
  NewCompanyUsersCompany, CompanyUsersCompanyIsEmpty
} from "@/models/company_users_company";


export default {
  name: "ModalEditCompany",
  components: {
  },
  props: {
    opened: Boolean,
    tab: Number,
    company: Object,
  },
  mixins: [InputsMixin, DragDrop],
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {
      isLoading: false,

      // data
      formDisabled: true,
      activeTab: 0,

      message: '',

      companyData: NewCompanies(),

      // drag and drop files
      reloadPreview: false,
      avatar: null,

      // access table params
      defaultAccess: [],
      table: {
        // is loading
        isLoading: false,
        // is re-search
        isReSearch: false,
        // has checkbox
        hasCheckbox: false,
        // hide pageng
        isHidePaging: true,
        // table title
        title: this.$t('InterfaceText.users_list'),
        // column data
        columns: [
          {
            label: this.$t('FieldsText.fio_label'),
            field: "fio",
            width: "5%",
            sortable: false,
            isKey: false,
          },
          {
            label: this.$t('FieldsText.role_label'),
            field: "role",
            width: "3%",
            sortable: false,
            isKey: false,
          },
          {
            label: this.$t('FieldsText.phone_short_label'),
            field: "phone",
            width: "3%",
            sortable: false,
            isKey: false,
          },
          {
            label: this.$t('FieldsText.email_label'),
            field: "email",
            width: "3%",
            sortable: false,
            isKey: false,
          },
          {
            label: this.$t('InterfaceText.read_access_mention'),
            field: "read",
            width: "3%",
            sortable: false,
            isKey: false,
          },
          {
            label: this.$t('InterfaceText.write_access_mention'),
            field: "write",
            width: "3%",
            sortable: false,
            isKey: false,
          },
        ],
        // tabular data
        rows: [],
        // number of rows per page
        pageSize: 7,
        // total number of rows
        total: 0,
        // // current page index
        page: 1,
        // sorting
        sortable: {},
        // messages: {
        //   pagingInfo: "Showing {0}-{1} of {2}",
        //   pageSizeChangeLabel: "Row count:",
        //   gotoPageLabel: "Go to page:",
        //   noDataAvailable: "No data",
        // },
      }

    };
  },
  mounted() {
    if (this.company) {
      this.companyData = CompaniesFromBody(this.company)
    }
  },
  created() {
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    company: function (newValue, oldValue) {
      if (!newValue) {
        this.companyData = NewCompanies()
      } else {
        this.companyData = CompaniesFromBody(this.company)

        // get avatar
        if(this.companyData.avatar == null || this.companyData.avatar === '')
          this.avatar = null
        else {
          this.avatar = {};
          this.reloadPreview = false;
          this.avatar.url = getImageUrl(this.companyData.avatar);
          this.reloadPreview = true;
        }

        // parse company users
        this.parseCompanyUsers()
      }

      //if(this.tab != null && this.activeTab !== this.tab) {
      if (this.tab != null && this.activeTab === null) {
        this.activeTab = this.tab
      }
      else if(this.activeTab == null) {
        this.activeTab = 0
      }

      this.scrollChatToLastPosition()
    },
    tab() {
      if(this.tab != null && this.activeTab !== this.tab)
        this.activeTab = this.tab
      else if(this.activeTab == null)
        this.activeTab = 0
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  methods: {
    ...mapGetters(["GET_USER","GET_EMPLOYEES"]),
    ...mapActions([
      "CREATE_COMPANY",
      "UPDATE_COMPANY",
      "SEND_COMPANY_MESSAGE",

      "CREATE_COMPANY_USER",
      "UPDATE_COMPANY_USER",
      "DELETE_COMPANY_USER",
    ]),

    UserTypeText,

    parseDate,
    parseTime,
    parseFullTime,

    getUserAvatarUrl,

    setUserReadAccess(user) {
      this.delay(100).then(() => {

        if(hasContent(this.table.rows)) {
          for(var i = 0; i < this.table.rows.length; i++) {
            if(this.table.rows[i].id === user.id) {
              if(this.table.rows[i].companies[0].CompanyUsers.write) {
                this.table.rows[i].companies[0].CompanyUsers.read = true
                break
              }
            }
          }
        }
      });
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },

    addCompany() {
      if(this.companyData == null) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.error_wrong_data'),
        });
      }
      else if(!CompaniesRequiredVerify(this.companyData)) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.missing_required_data'),
        });
      }
      else if(isEmpty(this.companyData.id)) {
        this.createCompany()
      }
      else {
        this.updateCompany()
      }
    },

    createCompany() {
      let formData = new FormData();
      formData.append("avatar", this.avatar);

      this.companyData.avatar = this.avatar ? formData : null;

      this.isLoading = true

      this.CREATE_COMPANY({
        ...this.companyData,
      }).then((res) => {
        this.isLoading = false

        if (res.success) {
          this.companyData.id = res.id
          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('CompanyText.company_created'),
          });
        } else if (res.error === "badPassword") {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.not_strong_password'),
          });
        } else if (res.error === "badModelData" && res.message != null &&
            res.message.name === "SequelizeUniqueConstraintError") {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('CompanyText.error_exists'),
          });
        } else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_try_again'),
          });
        }
      });
    },

    updateCompany() {
      let formData = new FormData();

      formData.append("avatar", this.avatar);
      formData.append("id", this.companyData.id);

      this.companyData.avatar = (this.avatar && this.avatar.upload) ? formData : null;

      this.isLoading = true

      this.UPDATE_COMPANY({
        ...this.companyData,
      }).then((res) => {
        this.isLoading = false

        if (res.success) {
          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('CompanyText.company_updated'),
          });
        }
        else if (res.error === "badPassword") {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.not_strong_password'),
          });
        }
        else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_try_again'),
          });
        }
      });
    },

    sendMessage() {
      if (hasContent(this.message))
        this.isLoading = true

        this.SEND_COMPANY_MESSAGE({
          id: this.companyData.id,
          message: this.message,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {
            this.message = '';
            this.scrollChatToLastPosition()

            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('ChatText.sent_success'),
            });
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.not_success_operation'),
              text: this.$t('ChatText.sent_error'),
            });
          }

        });
    },

    // drag and drop files
    async onChangeAvatar() {
      this.avatar = this.$refs.avatar_file.files[0];
      this.reloadPreview = false
      await getFileImage(this.avatar)
      this.reloadPreview = true
    },
    dropAvatar(event) {
      event.preventDefault();
      this.$refs.avatar_file.files = event.dataTransfer.files;
      this.onChangeAvatar();
    },

    scrollChatToLastPosition() {
      setTimeout(() => {
        let msgsContainer = document.getElementById("msgsContainer");

        if(msgsContainer) {
          msgsContainer.scrollTo({ top: msgsContainer.scrollHeight, behavior: 'smooth' })
        }
      }, 500);
    },

    doUserSearch(data) {
      console.log(data)
    },

    updateCheckedRows(data) {
      console.log(data)
    },

    parseCompanyUsers() {
      let users = []
      let employees = []

      if(this.editUserPermission) {
        // get access from users list
        employees = this.employeesActiveCopyExceptAdmin ? this.employeesActiveCopyExceptAdmin : [];

        // parse all employees and check permissions
        for(let i = 0; i < employees.length; i++) {
          let employee = JSON.parse(JSON.stringify(employees[i]));

          // check if user contains companies field
          employee.companies = employee.companies ? employee.companies : [];

          // check if user has any permissions for current company
          let company = employee.companies.find(c => c.id === this.companyData.id);
          if(isEmpty(company)) {
            // create new CompanyUsersObject
            company = NewCompanyUsersCompany(this.companyData, employee);
          }

          employee.companies = [company]
          users.push(employee)
        }
      }
      else {
        // parse all employees and check permissions
        for(let i = 0; i < this.companyData.users.length; i++) {
          let employee = JSON.parse(JSON.stringify(this.companyData.users[i]));

          employee.companies = [JSON.parse(JSON.stringify(employee))];
          users.push(employee);
        }
      }

      // save current access state
      this.defaultAccess = JSON.parse(JSON.stringify(users));

      this.table.rows = users;
      this.table.total = users.length;
    },

    saveCompanyUsers() {
      // check if is null object
      this.defaultAccess = this.defaultAccess ? this.defaultAccess : [];

      // check all fields and find differents
      for(let i = 0; i < this.table.rows.length; i++) {
        let company = this.table.rows[i].companies[0];
        company.CompanyUsers.userName = `${this.table.rows[i].name} ${this.table.rows[i].surname} ${this.table.rows[i].lastname}`
        company.CompanyUsers.companyName = this.table.rows[i].companies[0].name;
        company.CompanyUsers.email = this.table.rows[i].email;

        if(CompanyUsersCompanyIsNewAndEmpty(company)) {
          // no need any action
        }
        else if(CompanyUsersCompanyIsNew(company)) {
          this.isLoading = true

          // access was added!
          this.CREATE_COMPANY_USER({
            ...company.CompanyUsers,
          }).then((res) => {
            this.isLoading = false

            if(!res.success) {
              // parse company users
              this.parseCompanyUsers()
            }
          }).catch(() => {
            this.isLoading = false

            // parse company users
            this.parseCompanyUsers()
          });
        }
        else if(CompanyUsersCompanyIsEmpty(company)) {
          this.isLoading = true

          // access was removed!!
          this.DELETE_COMPANY_USER({
            ...company.CompanyUsers,
          }).then((res) => {
            this.isLoading = false

            if(!res.success) {
              // parse company users
              this.parseCompanyUsers()
            }
          }).catch(() => {
            this.isLoading = false

            // parse company users
            this.parseCompanyUsers()
          });
        }
        else {
          let defaultCompany = this.defaultAccess[i].companies[0];

          // compare current users access and configured access
          if(defaultCompany.CompanyUsers.read !== company.CompanyUsers.read ||
              defaultCompany.CompanyUsers.write !== company.CompanyUsers.write) {

            this.isLoading = true

            // access was changed!!!
            this.UPDATE_COMPANY_USER({
              ...company.CompanyUsers,
            }).then((res) => {
              this.isLoading = false

              if(!res.success) {
                // parse company users
                this.parseCompanyUsers()
              }
            }).catch(() => {
              this.isLoading = false

              // parse company users
              this.parseCompanyUsers()
            });
          }
        }
      }
    },

    close() {
      //this.activeTab = 0;
      this.activeTab = null;
      this.avatar = null;

      this.$emit('close-modal');
    }

  },
  computed: {
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN) : false;
    },

    employees() {
      return this.GET_EMPLOYEES().all;
    },


    employeesActiveCopy() {
      return this.employees.filter(v => v.active === true);
    },

    employeesActiveCopyExceptAdmin() {
      return this.employees.filter(v => v.active === true && v.role !== UsersType.ADMIN);
    },

    cardName() {
      if(this.companyData == null || this.companyData.id == null || this.companyData.id === '')
        return this.$t('CompanyText.new_company')
      else
        return this.$t('CompanyText.info_company')
    },
    saveBtName() {
      if(this.companyData == null || this.companyData.id == null || this.companyData.id === '')
        return this.$t('Buttons.add')
      else
        return this.$t('Buttons.save')
    },

    mainTabVisibility() {
      return true;
    },
    usersTabVisibility() {
      return hasContent(this.companyData) && hasContent(this.companyData.id);
    },
    chatTabVisibility() {
      //return hasContent(true);
      return hasContent(this.companyData) && hasContent(this.companyData.id);
    },
    historyTabVisibility() {
      //return true;
      return hasContent(this.companyData) && hasContent(this.companyData.id) && this.editPermission;
    },
    editPermission() {
      if(this.isAdmin)
        return true
      else {
        // find type of access for current company
        let companies = this.user ? this.user.companies : [];

        if(this.companyData == null || companies == null || companies.length === 0)
          return false
        else {
          let company = companies.find(c => c.id === this.companyData.id);

          if(company == null || company.CompanyUsers == null)
            return false;
          else
            return company.CompanyUsers.write;
        }
      }
    },
    editUserPermission() {
      return this.isAdmin;
    },
  },
};
</script>

<style scoped lang="scss">
  @import "@/assets/scss/_variables.scss";
  @import "@/assets/scss/_mixin.scss";

  ::v-deep(.vtl-table .vtl-thead .vtl-thead-th) {
    color: #000;
    background-color: #fff;
    border-color: #dedede;
  }

  .tableLite {
    margin-left: -10px;
    margin-top: -45px;
    height: 450px;
    width: inherit;
    overflow: hidden;
    overflow-y: scroll;
  }

  .v-cloak {
  display: none;
  }

  .file_input {
    margin-bottom: -47px;
    width: inherit;
    height: inherit;
    opacity: 0;
    overflow: hidden;
    position: absolute;
  }

  .avatar {
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }

  .modal {
    @include modal-base;
    .wrapper {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
      overflow: hidden;
      border-radius: 10px;
      background: #fff;
      width: 1032px;
      padding-bottom: 1px;
      @include modal-base-header;
      @include modal-base-content;
      .header {
        background: $mainBgColor;
      }
      .vs__dropdown-toggle {
        height: 24px !important;
      }
      .content {
        .user-info {
          @include d-flex-row-n;
          .edit-buttons {
            @include d-flex-col-n;
            .avatar {
              border-radius: 5px;
              overflow: hidden;
              display: flex;
              flex-flow: row nowrap;
              position: relative;
              width: 158px;
              height: 132px;
              align-items: center;
              justify-content: center;
              img {
                width: 100%;
                height: 100%;
              }
              .edit-overlay {
                background: rgba(0, 0, 0, 0.3);
                @include d-flex-row-n;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
                .image-input {
                  left: 0;
                  top: 0;
                  opacity: 0;
                  display: block;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  cursor: pointer;
                }
              }
            }
            .edit-button {
              margin-top: 9px;
              @include ui-default-button(#fff, $blue);
            }
            .save-button {
              margin-top: 11px;
              @include ui-default-button($blue, #fff);
            }
          }
          .edit-inputs {
            @include d-flex-col-n;
            width: 241px;
            margin-left: 33px;
            & > *:first-child ~ * {
              margin-top: 5px;
            }
          }
        }
        .chat-history {
          .tabs-wrapper {
            display: flex;
            .tab-chat,
            .tab-history {
              background-color: $white;
              border-radius: 10px;
              width: 123px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: 0px -3px 4px 0px #0000000d;
              cursor: pointer;
              position: relative;
              p {
                color: $blue;
              }
              .shadow {
                position: absolute;
                bottom: -10px;
                left: -6px;
                width: 144px;
                height: 10px;
                box-shadow: 0px -3px 4px 0px #0000000d;
                z-index: 1;
              }
            }
            .tab-chat {
              margin-right: 10px;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                width: 10px;
                height: 20px;
                bottom: -10px;
                left: 0;
                background-color: $white;
              }
              &::after {
                content: "";
                width: 20px;
                height: 10px;
                position: absolute;
                bottom: -1px;
                right: -10px;
                background-color: $white;
                clip-path: polygon(
                        52% 23%,
                        51% 10%,
                        50% 0%,
                        0% 0%,
                        0% 25%,
                        0% 100%,
                        100% 100%,
                        92% 96%,
                        84% 90%,
                        73% 80%,
                        67% 70%,
                        62% 59%,
                        58% 47%,
                        55% 36%
                );
              }
              .shadow {
                left: 0;
                width: 128px;
              }
            }
            .tab-history {
              position: relative;
              &::before {
                content: "";
                position: absolute;
                width: 20px;
                height: 10px;
                bottom: -1px;
                left: -10px;
                background-color: $white;
                transform: scale(-1, 1);
                clip-path: polygon(
                        52% 23%,
                        51% 10%,
                        50% 0%,
                        0% 0%,
                        0% 25%,
                        0% 100%,
                        100% 100%,
                        92% 96%,
                        84% 90%,
                        73% 80%,
                        67% 70%,
                        62% 59%,
                        58% 47%,
                        55% 36%
                );
              }

              &::after {
                content: "";
                width: 20px;
                height: 10px;
                position: absolute;
                bottom: -1px;
                right: -10px;
                background-color: $white;
                clip-path: polygon(
                        52% 23%,
                        51% 10%,
                        50% 0%,
                        0% 0%,
                        0% 25%,
                        0% 100%,
                        100% 100%,
                        92% 96%,
                        84% 90%,
                        73% 80%,
                        67% 70%,
                        62% 59%,
                        58% 47%,
                        55% 36%
                );
              }
            }
          }
          .main-wrapper {
            border-radius: 10px;
            background-color: $white;
            //padding: 30px 30px 40px;
            box-shadow: 0px -3px 4px 0px #0000000d;
          }
          .chat-wrapper {
            border-radius: 10px;
            background-color: $white;
            padding: 12px 25px 25px 25px;
            box-shadow: 0px -3px 4px 0px #0000000d;
            .msgs-container {
              margin-bottom: 27px;
              height: 454px;
              overflow-y: scroll;
              .msg-container {
                max-width: 77%;
                display: flex;
                margin-bottom: 13px;
                img {
                  width: 40px;
                  height: 40px;
                  margin-right: 20px;
                }
                .msg-wrapper {
                  display: flex;
                  flex-direction: column;
                  .msg {
                    margin-bottom: 5px;
                    padding: 7px 33px 6px 13px;
                    background-color: #f2f2f2;
                    border-radius: 100px;
                    p {
                      color: $activeText;
                      margin: 0;
                    }
                    .msg-info {
                      display: flex;
                      margin-bottom: 5px;
                      .name {
                        font-size: 12px;
                        font-weight: 500;
                        margin-right: 15px;
                      }
                      .date-time {
                        color: $chatNotActiveText;
                        font-size: 10px;
                      }
                    }
                    .text {
                      font-size: 12px;
                      font-weight: 300;
                      flex-grow: 1;
                    }
                  }
                  .answer-button {
                    color: $textGray;
                    font-size: 10px;
                  }
                }
                &.self-message {
                  margin: 0 0 13px auto;
                  justify-content: flex-end;
                  img {
                    order: 2;
                    margin-right: 10px;
                    margin-left: 20px;
                  }
                  .msg-wrapper {
                    .answer-button {
                      display: inline-block;
                      cursor: pointer;
                      text-align: right;
                    }
                  }
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
            .chat-input {
              display: flex;
              .avatar {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 18px;
              }
              .chat-form {
                display: flex;
                width: 100%;
                .chat-message {
                  flex-grow: 1;
                  margin-right: 10px;
                  padding: 12px;
                  border: 1px solid $inputCaption;
                  border-radius: 5px;
                  font-size: 12px;
                  font-weight: 500;
                  &::placeholder {
                    color: $textGray;
                    font-size: 12px;
                  }
                }
                .send-message {
                  width: 158px;
                  border: 1px solid $purple;
                  border-radius: 5px;
                  background-color: $white;
                  box-shadow: 0px 0px 4px 0px #00000040;
                  color: $purple;
                  &:hover {
                    cursor: pointer;
                  }
                }
                .send-message-inactive {
                  width: 158px;
                  border: 1px solid #00000040;
                  border-radius: 5px;
                  background-color: $white;
                  box-shadow: 0px 0px 4px 0px #00000040;
                  color: #00000040;
                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .history-wrapper {
            background-color: $white;
            padding: 12px 25px 25px 25px;
            box-shadow: 0px -3px 4px 0px #0000000d;
            border-radius: 10px;
            .inner {
              .table-wrapper {
                height: 520px;
                overflow-y: scroll;
                .column-names {
                  padding: 7px;
                  margin-bottom: 5px;
                  display: flex;
                  justify-content: space-between;
                  background-color: $photoBg;
                  border-radius: 5px;
                  div {
                    color: $textGray;
                    text-align: center;
                  }
                  .date {
                    width: 114px;
                  }
                  .time {
                    width: 93px;
                  }
                  .author {
                    width: 133px;
                  }
                  .changes {
                    width: 362px;
                  }
                }
                .table {
                  .table-string {
                    display: flex;
                    justify-content: space-between;
                    .table-cell {
                      padding: 7px 0 13px;
                      color: $textGray;
                      text-align: center;
                      position: relative;
                      &:nth-child(1) {
                        width: 114px;
                      }
                      &:nth-child(2) {
                        width: 93px;
                      }
                      &:nth-child(3) {
                        width: 133px;
                      }
                      &:nth-child(4) {
                        width: 362px;
                      }
                      &::after {
                        position: absolute;
                        content: "";
                        bottom: 0;
                        left: 0;
                        right: 0;
                        border-radius: 5px;
                        height: 1px;
                        background-color: $photoBg;
                      }
                    }
                  }
                }
              }
            }
          }
          .active {
            display: block;
          }
        }

        .dd {
          position: relative;
          width: 250px;
          &.object {
            width: 100% !important;
            margin-top: 0px !important;
            font-size: 12px;
            font-weight: 500;
            // .dd-button {
            //   height: 27px !important;
            // }
          }
          .dd-button {
            @include ui-dropdown-button();
            border-radius: 0px 5px 5px 0px;
          }
          & ::v-deep .vs__dropdown-toggle {
            border: 1px solid $inputCaption;
            box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
          }
          &:not(:first-child) {
            margin-top: 10px;
          }
        }

        .avatar-preview {
          display: flex;
          flex-wrap: wrap;
          &__item {
            width: 180px;
            height: 180px;
            //border-radius: 50%;
            border-radius: 5%;
            margin: auto;
            cursor: pointer;
            &:not(:last-child) {
              margin-right: 30px;
            }
          }
          &__img {
            width: 70px;
            height: 70px;
          }
        }

        .input-wrapper {
          margin-bottom: 10px;
          .caption {
            font-size: 12px;
            color: $inputCaption;
          }
          input {
            border: none;
            border-bottom: 1px solid #d9d9d9;
            color: rgba(#1e4fdf, 0.17);
            outline: none;
            font-size: 14px;
            color: #000000;
            width: 100%;
          }
          textarea {
            border: none;
            border-bottom: 1px solid #d9d9d9;
            color: rgba(30, 79, 223, 0.17);
            outline: none;
            font-size: 14px;
            color: #4f4f4f;
            width: 100%;
            resize: none;
          }
        }

      }
    }
    .date-picker {
      align-items: center;
      .d-comp {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        opacity: 0;
        background: black;
        // z-index: 99999;
      }
      .date-start {
        position: relative;
        input {
          @include ui-input($pl: 11px, $w: 265px);
        }
      }
      .date-pick {
        position: relative;
      }
    }
  }

  .new-account {
    display: flex;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 1032px;
    height: 558px;
    background-color: $white;
    border-radius: 10px;
    padding: 50px 145px 40px 30px;
    // &__inner {
    //     display: flex;
    //     flex-flow: row nowrap;
    // }

    &__validation-rule {
      margin-bottom: 30px;
      span {
        color: $red;
      }
      &-back {
        @include white-button;
        width: 190px;
      }
      &-forward {

      }
    }
  }

  .new-account__columns {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 30px;
  }

  .new-account__column {
    &:first-child {
      margin-right: 30px;
    }
  }

  .new-account__inner-header {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .new-account__item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 265px;
    margin-bottom: 20px;
    &-text {
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 5px;
      color: $black;
      span {
        color: $red;
      }
    }

    &-input {
      @include search-input;
    }

    &.textarea {
      grid-column: span 3;
      input {
        border-radius: 5px;
        width: 100%;
      }
    }
  }

</style>
