<template>
  <section class="login">
    <img class="login__logo" src="@/assets/img/logo.png" alt="logo">
    <div class="login__auth">
      <h2 class="login__auth-header">
        Авторизация
      </h2>
      <form
          class="login__form"
          @submit.prevent="login"
      >
        <input
            class="login__auth-login"
            type="email"
            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
            :placeholder="$t('LoginText.login')"
            v-model="email"
        >
        <input
            class="login__auth-pass"
            type="password"
            :placeholder="$t('LoginText.password')"
            v-model="password"
        >
        <div class="login__options">
          <label style="visibility: hidden;" class="login__checkbox">
            <input class="login__checkbox-checkbox" type="checkbox">
            <span class="login__checkbox-fake"></span>
            <div class="login__checkbox-text">{{ $t('LoginText.remember') }}</div>
          </label>
          <p class="login__forgot" @click="restorePassword" style="cursor: pointer;">
            {{ $t('LoginText.restore') }}
          </p>
        </div>

        <button class="login__button" type="submit">{{ $t('Buttons.sign_in') }}</button>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  mounted() {
    let user = this.GET_USER();
    if (user?.token) {
      this.$router.push({ name: "Home" }).catch(() => {});
    }
  },
  methods: {
    ...mapGetters(["GET_USER"]),
    ...mapActions(["LOGIN_WITH_PASSWORD", "RECOVERY_PASSWORD"]),
    login() {
      this.LOGIN_WITH_PASSWORD({
        email: this.email,
        password: this.password,
      }).then((res) => {
        if (!res.success) {
          let title = this.$t('LoginText.error_auth');
          let text = this.$t('Errors.error_try_again');

          if(res.error === 'invalidCredentialsNotFoundUser') {
            text = this.$t('LoginText.error_user_not_exists');
          }
          else if(res.error === 'invalidCredentialsWrongPassword') {
            text = this.$t('LoginText.error_wrong_password');
          }
          else if(res.error === 'accountInactive') {
            text = this.$t('LoginText.error_account_inactive');
          }
          else if(res.error === 'hasNoAccessToThePlatform') {
            text = this.$t('LoginText.error_no_access_to_the_platform');
          }

          this.$notify({
            type: "error",
            title: title,
            text: text,
          });
        } else {
          this.email = this.password = "";
          this.$router.push({ name: "Home" }).catch(() => {});
        }
      });
    },
    restorePassword() {
      if (confirm(this.$t('LoginText.restore_confirm'))) {
        //callback to execute when user confirms the action
        this.RECOVERY_PASSWORD({
          email: this.email,
        }).then((res) => {
          if (!res.success) {
            let title = this.$t('LoginText.error_restore');
            let text = this.$t('Errors.error_try_again');

            if(res.error === 'invalidCredentials') {
              text = this.$t('LoginText.error_user_not_exists');
            }
            else if(res.error === 'maxRecoveryTrys') {
              text = this.$t('LoginText.error_account_inactive');
            }
            else if(res.error === 'accountInactive') {
              text = this.$t('LoginText.error_account_inactive');
            }

            this.$notify({
              type: "error",
              title: title,
              text: text,
            });
          } else {
            this.password = "";

            this.$notify({
              type: "success",
              title: this.$t('LoginText.error_success'),
              text: this.$t('LoginText.error_success_message'),
            });
          }
        });
      }
    },
  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.login {
  flex: 1;
  width: 100%;
  max-width: 767px;
  margin: 0 auto;
  &__logo {
    width: 767px;
    height: 318px;
    margin-bottom: 65px;
  }

  &__auth {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    max-width: 482px;
    margin: 0 auto;
    padding: 30px 45px 40px 37px;
    background: $white;
    border-radius: 10px;
    &-header {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 30px;
      text-align: center;
    }

    &-login,
    &-pass {
      font-size: 12px;
      font-weight: 500;
      padding-top: 8px;
      padding-bottom: 8px;
      text-align: center;
      outline: none;
      border-radius: 5px;
      border: none;
      box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
      &::placeholder {
        font-size: 12px;
        font-weight: 500;
        color: $chatNotActiveText;
      }
    }

    &-login {
      margin-bottom: 27px;
    }

    &-pass {
      margin-bottom: 10px;
    }
  }

  &__form {
    display: flex;
    flex-flow: column nowrap;
  }

  &__options {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 23px;
  }

  &__forgot {
    color: $purple;
  }

  .login__checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0;
    &-checkbox {
      display: none;
    }
    &-fake {
      display: inline-block;
      width: 17px;
      height: 17px;
      margin-right: 12px;
      border-radius: 2px;
      box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
      position: relative;
      &::before {
        content: '✔';
        position: absolute;
        color: $black;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
      }
    }
    &-text {
      font-size: 12px;
      font-weight: 500;
      color: $activeText;
    }
    .login__checkbox-checkbox:checked + .login__checkbox-fake::before {
      opacity: 1;
    }
    span {
      color: $red;
    }
  }

  &__button {
    @include purple-button;
    color: #ffffff;
    width: 190px;
    margin: 0 auto;
  }
}

</style>
