<template>
  <ModalEditCodesConfig
      :opened="AddNewCodeModalOpened"
      :codeConfig="codeConfig"
      @close-modal="AddNewCodeModalClose"
  />
  <ModalCodeHistory
      :opened="CodeModalHistoryOpened"
      :codeData="codeData"
      @close-modal="closeHistoryCode"
  />
  <BlockCodeModal
    v-if="BlockCodeModalOpened"
    :promotion="filterData.promotion"
    @close-modal="BlockCodeModalClose()"
  />
  <DeleteCodeModal
    v-if="DeleteCodeModalOpened"
    @confirm-modal="DeleteCodeModalConfirm()"
    @close-modal="DeleteCodeModalClose()"
  />
  <div class="codes">
    <div class="page-header">
      <h2 class="page-header__title">
        {{ pageHeaderText }}
      </h2>
      <div class="buttons-block">

        <div v-if="currentCodes && currentCodes.length > 0"
             class="page-header__outload"
        >
          <img
              width="20" height="16"
              src="@/assets/img/bi_cloud_download.svg"
              alt="notification avatar">
          <p class="page-header__outload-text" @click="downloadCodesQR()">
            {{ $t('Buttons.download_qr') }}
          </p>
        </div>

        <div v-if="currentCodes && currentCodes.length > 0"
             class="page-header__blockcode"
        >
          <img
              width="20" height="16"
              src="@/assets/img/bi_cloud_download.svg"
              alt="notification avatar">
          <p class="page-header__outload-text" @click="downloadCodesList('xls')">
            {{ $t('Buttons.download_xls') }}
          </p>
        </div>
        <div v-if="filterData.promotion"
          class="page-header__blockcode"
          @click="BlockCodeModalOpen()"
        >
          <p class="page-header__blockcode-text">
            {{ $t('CodesText.block_codes') }}
          </p>
        </div>
        <div v-if="filterData.promotion"
          class="page-header__newcode"
          @click="AddNewCodeModalOpen()"
        >
          <p class="page-header__newcode-text">
            {{ $t('Buttons.generation') }}
          </p>
        </div>
      </div>
    </div>
    <div class="buttons-panel">

      <div class="buttons-panel__input">
        <p class="buttons-panel__input-text">
          {{ $t('InterfaceText.search') }}
        </p>
        <input type="text" class="buttons-panel__input-input" :placeholder="$t('FieldsText.enter_request')"
               :value="filterData.search"
               @input="setCodesSearch">
        <div class="buttons-panel__input-button"></div>
      </div>

      <div class="buttons-panel__input select">
        <p class="buttons-panel__input-text">
          {{ $t('FieldsText.company_label') }}
        </p>
        <div class="dd object dd-company-filter">
          <v-select
              :placeholder="$t('FieldsText.select_from_list')"
              :options="companies"
              :reduce="(option) => option.id"
              label="name"
              :clearable="false"
              v-model="filterData.company"
              @option:selected="setEmployeesCompany"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              {{ $t('Errors.missing_options') }}
            </template>
          </v-select>
          <div
              class="dd-button"
              @click="openSelectOptions('.dd-company-filter .vs__dropdown-toggle')"
          ></div>
        </div>
      </div>

      <div class="buttons-panel__input select">
        <p class="buttons-panel__input-text">
          {{ $t('FieldsText.promotion_label') }}
        </p>
        <div class="dd object dd-role-filter">
          <v-select
              :placeholder="$t('FieldsText.select_from_list')"
              :options="promotions"
              :reduce="(option) => option.id"
              label="name"
              :clearable="false"
              v-model="filterData.promotion"
              @option:selected="setCodesPromotion"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              {{ $t('Errors.missing_options') }}
            </template>
          </v-select>
          <div
              class="dd-button"
              @click="openSelectOptions('.dd-role-filter .vs__dropdown-toggle')"
          ></div>
        </div>
      </div>

    </div>

    <div class="table-5">
      <div class="table__row title">
        <p class="table__cell">{{ $t('FieldsText.code_label') }}</p>
        <p class="table__cell">{{ $t('FieldsText.company_label') }}</p>
        <p class="table__cell">{{ $t('FieldsText.promotion_label') }}</p>
        <p class="table__cell">{{ $t('FieldsText.status_label') }}</p>
        <p class="table__cell">{{ $t('TableHeaders.action') }}</p>
      </div>
      <div class="table__body">
        <div
          v-for="code in currentCodes" :key="code.id"
          class="table__row"
        >
          <div class="table__cell">
            <p>
              {{ code.code }}
            </p>
          </div>
          <div class="table__cell">
            <p>
              {{ (code && code.fk_promotion && code.fk_promotion.fk_company) ? code.fk_promotion.fk_company.name : '' }}
            </p>
          </div>
          <div class="table__cell">
            <p>
              {{ (code && code.fk_promotion) ? code.fk_promotion.name : '' }}
            </p>
          </div>
          <div class="table__cell">
            <p>
              {{ CodesStatusText(code.status) }}
            </p>
          </div>
          <div class="table__cell button">
            <div v-if="editPermissionCompany" class="table__cell-button" style="padding-left: 5px;" @click="openModalBlockCode(code)">
              <img v-if="code.status === CodesStatus.ACTIVE"
                  :title="$t('TabHeaders.deactivate')"
                  src="@/assets/img/icon_block.svg"
                  alt="notification avatar">
              <img v-else-if="code.status === CodesStatus.BLOCKED"
                  :title="$t('TabHeaders.activate')"
                  src="@/assets/img/icon_blocked.svg"
                  alt="notification avatar">
            </div>
            <div v-if="editPermissionCompany" class="table__cell-button" style="padding-left: 5px;" @click="openHistoryCode(code)">
              <img
                  :title="$t('TabHeaders.information')"
                  src="@/assets/img/icons/info.svg"
                  alt="notification avatar">
            </div>
            <div v-if="editPermissionCompany && code.status !== CodesStatus.USED" class="table__cell-button" style="padding-left: 5px;" @click="openModalDeleteCode(code)">
              <img
                  :title="$t('Buttons.delete')"
                  src="@/assets/img/icon_delete.svg"
                  alt="notification avatar">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--div class="pagination-buttons">
      <div class="pagination-buttons__arrow left">
        <img src="@/assets/img/left-arrow.png" alt="arrow icon">
      </div>
      <div v-bind:class="(n===page)?'pagination-buttons__number active':'pagination-buttons__number'"
           @click="changePage(n)"
           v-for="n in pageCount" :key="n"
      >{{ n }}</div>
      <div class="pagination-buttons__arrow right">
        <img src="@/assets/img/left-arrow.png" alt="arrow icon">
      </div>
    </div-->
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import InputsMixin from "@/mixins/inputsEvent";

import ModalEditCodesConfig from '../components/ModalEditCodesConfig.vue'
import ModalCodeHistory from '../components/ModalCodeHistory.vue'

import BlockCodeModal from '../components/BlockCodeModal.vue'
import DeleteCodeModal from '../components/DeleteCodeModal.vue'

import {UsersType} from "@/models/users";
import {CodeConfigFromBody} from "@/models/codeConfig";

import {hasContent, isEmpty} from "@/tools/dataTools";
import {parseFullWithSecsTime} from "@/tools/dateTools";
import {CodesStatus, CodesStatusText} from "@/models/codes";
import {SAVE_PAGES_FILTERS} from "@/common/config";

export default {
  name: 'Codes',
  components: {
    ModalEditCodesConfig,
    ModalCodeHistory,
    BlockCodeModal,
    DeleteCodeModal
  },
  mixins: [InputsMixin],
  data() {
    return {
      // pagination
      isLoading: false,
      itemsPerPage: 100,
      page: 1,

      // dialogs
      codeConfig: null,
      codeData: null,
      AddNewCodeModalOpened: false,
      CodeModalHistoryOpened: false,
      BlockCodeModalOpened: false,
      DeleteCodeModalOpened: false,

      // data
      filterData: {
        search: '',
        company: null,
        promotion: null,
      },

      selectedPromotion: null,
      selectedCode: null,

      currentCodes: [],
    }
  },
  beforeMount() {
    this.CLEAR_CODES()
  },
  mounted() {
    if (this.user?.token) {
      if (SAVE_PAGES_FILTERS) {
        this.filterData = {
          search: this.codes_filter.search,
          company: this.employees_filter.company,
          promotion: this.codes_filter.promotion,
        }
      }

      this.fillCodeConfigData(this.filterData.promotion)

      this.getCodes()
      this.getNextCodes()
    }
    else {
      this.$router.push({ name: "Login" }).catch(() => {});
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    "$store.state.employees.filter": function (newValue, oldValue) {
      if(this.user) {
        if(newValue != null) {
          this.filterData.company = newValue.company
        }

        this.filterItems();
      }
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.codes.filter": function (newValue, oldValue) {
      if(newValue != null) {
        this.filterData.search = newValue.search
        this.filterData.promotion = newValue.promotion
      }

      if(newValue.search !== oldValue.search)
        this.filterItems();
      else if(newValue.promotion !== oldValue.promotion) {
        // clear all data to start new data request
        this.currentCodes = []
        this.getCodes();
      }
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.codes.all": function (newValue, oldValue) {
      this.filterItems();
    },
    pageCount() {
      if(this.pageCount < this.itemsPerPage)
        this.page = 1;
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  methods: {
    ...mapGetters(["GET_USER","GET_COMPANIES","GET_PROMOTIONS","GET_EMPLOYEES","GET_CODES",]),
    ...mapActions(["SET_EMPLOYEES_FILTER","SET_CODES_FILTER","GET_CODES_FROM","ACTIVATE_CODE","DEACTIVATE_CODE","DELETE_CODE","GET_CODES_DOWNLOAD","CLEAR_CODES",]),

    CodesStatusText,

    changePage(page) {
      this.page = page;
    },

    AddNewCodeModalOpen() {
      this.AddNewCodeModalOpened = true;
    },
    AddNewCodeModalClose() {
      this.getCodes()
      this.AddNewCodeModalOpened = false;
    },
    BlockCodeModalOpen() {
      this.BlockCodeModalOpened = true;
    },
    BlockCodeModalClose() {
      this.getCodes()
      this.BlockCodeModalOpened = false;
    },

    DeleteCodeModalClose() {
      this.DeleteCodeModalOpened = false;
    },
    DeleteCodeModalConfirm() {
      this.DeleteCodeModalOpened = false;

      if (this.selectedCode) {
        this.isLoading = true

        this.DELETE_CODE({
          id: this.selectedCode.id,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {
            this.getCodes();

            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('CodesText.delete_code_success'),
            });
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.not_success_operation'),
              text: this.$t('CodesText.delete_code_error'),
            });
          }
        });
      }
    },

    getNextCodes() {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.getCodes()
        }
      }
    },

    getCodes() {
      if(this.user) {
        this.isLoading = true

        let page = this.currentCodes ? Math. floor(this.currentCodes.length / this.itemsPerPage) : 0

        this.GET_CODES_FROM({
          promotion: this.filterData.promotion,
          search: this.filterData.search,
          page: page,
        }).then((res) => {
          this.isLoading = false

          if (!res.success) {
            this.$notify({
              type: "error",
              title: this.$t('Errors.not_success_operation'),
              text: res.message ? res.message : this.$t('CodesText.delete_code_error'),
            });
          }
        });
      }
    },

    // filters
    filterItems() {
      let currentCodes = this.codes;

      // filter by search value
      if (hasContent(this.filterData.search))
        currentCodes = currentCodes.filter(
            (v) =>
                `${v.code ? v.code : ''}`
                    .toLowerCase()
                    .indexOf(this.filterData.search.toLowerCase()) !== -1
        );

      // filter by current user company - if user is from specific company - show just current company users
      if (hasContent(this.user) && hasContent(this.user.company))
        currentCodes = currentCodes.filter(
            (v) => v.company === this.user.company
        );

      // attach all items
      process.nextTick(() => {
        this.currentCodes = currentCodes;
      });
    },

    setCodesSearch(e) {
      if(hasContent(e.target.value)) {
        this.filterData.search = e.target.value
        this.getCodes()
      }

      if (SAVE_PAGES_FILTERS) {
        this.SET_CODES_FILTER({
          ...this.codes_filter,
          search: e.target.value,
        });
      }
    },

    setEmployeesCompany(data) {
      this.SET_EMPLOYEES_FILTER({
        ...this.employees_filter,
        company: !data ? null : data.id,
      });

      // remove existing data
      process.nextTick(() => {
        this.filterData.promotion = null
        this.currentCodes = []
      });
    },

    fillCodeConfigData(id) {
      let promotion = null;

      if(id) {
        promotion = this.promotions ? this.promotions.find(p => p.id === id) : null;
      }

      this.codeConfig = CodeConfigFromBody(promotion ? promotion.codes : null);

      if(promotion) {
        this.codeConfig.promotion = promotion.id
        this.codeConfig.promotionName = promotion.name

        this.codeConfig.company = promotion.company
        this.codeConfig.companyName = this.getCompanyName(promotion.company)
      }
    },

    setCodesPromotion(data) {
      this.selectedPromotion = data

      this.fillCodeConfigData(data ? data.id : null)

      this.SET_CODES_FILTER({
        ...this.codes_filter,
        promotion: !data ? null : data.id,
      });
    },


    // eslint-disable-next-line no-unused-vars
    openModalBlockCode(code) {
      if(code.status === 'active') {
        this.deactivateCode(code)
      }
      else {
        this.activateCode(code)
      }
    },


    // eslint-disable-next-line no-unused-vars
    openModalDeleteCode(code) {
      this.deleteCode(code)
    },


    // eslint-disable-next-line no-unused-vars
    openHistoryCode(code) {
      this.codeData = code;
      this.CodeModalHistoryOpened = true;
    },

    closeHistoryCode() {
      this.CodeModalHistoryOpened = false;
    },

    activateCode(code) {
      if(code != null) {
        if (
            confirm(this.$t('CodesText.activate_code'))
        ) {
          this.isLoading = true

          this.ACTIVATE_CODE({
            id: code.id,
          }).then((res) => {
            this.isLoading = false

            if (res.success) {
              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('CodesText.activate_code_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('CodesText.activate_code_error'),
              });
            }
          });
        }
      }
    },

    deactivateCode(code) {
      if(code != null) {
        if (
            confirm(this.$t('CodesText.deactivate_code'))
        ) {
          this.isLoading = true

          this.DEACTIVATE_CODE({
            id: code.id,
          }).then((res) => {
            this.isLoading = false

            if (res.success) {
              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('CodesText.deactivate_code_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('CodesText.deactivate_code_error'),
              });
            }
          });
        }
      }
    },

    deleteCode(code) {
      if(code != null) {
        this.selectedCode = code
        this.DeleteCodeModalOpened = true
      }
    },

    // requests
    downloadCodesList(format) {
      if (format) {
        this.$notify({
          id: 'uploadDocCodes',
          duration: 9999999999,
          type: "notification",
          title: this.$t('Errors.wait_finish'),
          text: this.$t('CodesText.download_doc_started'),
        });

        console.log("--------------------\n")
        console.log("Start codes get XLS\n")
        console.log("time: " + parseFullWithSecsTime(new Date()))

        this.isLoading = true

        // download specific format
        this.GET_CODES_DOWNLOAD({
          format,
          promotion: this.filterData.promotion,
          promotionName: this.selectedPromotion ? this.selectedPromotion.name : '',
        }).then(() => {
          this.isLoading = false

          console.log("--------------------\n")
          console.log("Finish codes get XLS\n")
          console.log("time: " + parseFullWithSecsTime(new Date()))

          this.$notify.close('uploadDocCodes');
        }).catch(() => {
          this.isLoading = false

          this.$notify.close('uploadDocCodes');
        });

      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

    downloadCodesQR() {
      this.$notify({
        id: 'uploadCodes',
        duration: 9999999999,
        type: "notification",
        title: this.$t('Errors.wait_finish'),
        text: this.$t('CodesText.download_qr_started'),
      });

      console.log("--------------------\n")
      console.log("Start codes get ZIP\n")
      console.log("time: " + parseFullWithSecsTime(new Date()))

      this.isLoading = true

      //download archive with qr codes
      this.GET_CODES_DOWNLOAD({
        format: 'zip',
        promotion: this.filterData.promotion,
        promotionName: this.selectedPromotion ? this.selectedPromotion.name : '',
      }).then(() => {
        this.isLoading = false

        console.log("--------------------\n")
        console.log("Finish codes get ZIP\n")
        console.log("time: " + parseFullWithSecsTime(new Date()))

        this.$notify.close('uploadCodes');
      }).catch(() => {
        this.isLoading = false

        this.$notify.close('uploadCodes');
      });
    },

    //permissions
    editPermissionCompany(company) {
      if(this.isAdmin)
        return true
      else {
        // find type of access for current company
        let companies = this.user ? this.user.companies : [];

        if(company == null || companies == null || companies.length === 0)
          return false
        else {
          let userCompany = companies.find(c => c.id === company.id);

          if(userCompany == null || userCompany.CompanyUsers == null)
            return false;
          else
            return userCompany.CompanyUsers.write;
        }
      }
    },

    getCompanyName(id) {
      if(isEmpty(id))
        return null;
      else {
        let company = this.companies.find(company => company.id === id);

        if(company)
          return company.name;
        else
          return null;
      }
    },

  },

  computed: {
    CodesStatus() {
      return CodesStatus
    },
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN) : false;
    },
    pageCount() {
      //return this.currentCodes ? ~~(this.currentCodes.length / this.itemsPerPage) + 1 : 1;
      return this.currentCodes ? Math.ceil(this.currentCodes.length / this.itemsPerPage) : 1;
    },
    employees_filter() {
      return this.GET_EMPLOYEES().filter;
    },
    codes_filter() {
      return this.GET_CODES().filter;
    },
    codes() {
      return this.GET_CODES().all;
    },
    totalCount() {
      return this.GET_CODES().totalCount;
    },
    pageHeaderText() {
      let totalCount = this.totalCount;
      let title = this.$t('NavigationDrawerText.nav_codes');

      if(isEmpty(totalCount) || totalCount === 0) {
        return title;
      }
      else {
        return `${title} (${totalCount})`
      }
    },
    companies() {
      if(isEmpty(this.user)) {
        return [];
      }
      else {
        if(this.isAdmin) {
          return [
            {id: null, name: this.$t('CompanyText.all_companies')},
            ...this.GET_COMPANIES()
          ]
        }
        else {
          return this.user ? this.user.companies : []
        }
      }
    },
    promotions() {
      if(isEmpty(this.user)) {
        return [];
      }
      else {
        let promotions = this.GET_PROMOTIONS().all;

        if (!this.isAdmin) {
          promotions = promotions ? promotions.filter(p => p.company === this.employees_filter.company) : []
        }

        //  check of promotions missing
        if (promotions == null || promotions.length === 0) {
          promotions = []
          this.setCodesPromotion(null);
        }

        return promotions;
      }
    },
  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.codes {
  flex: 1;
  width: 100%;
  min-width: 1150px;

  .table {
    @include grid-table-row(145px, 60px, 11.8% 136px 13.75% 136px 14% 153px 8.2% 125px 6%);

    &__cell {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 2;
      }
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 4;
      }
      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 6;
      }
      &:nth-child(4) {
        grid-column-start: 7;
        grid-column-end: 8;
      }
      &:nth-child(5) {
        grid-column-start: 9;
        grid-column-end: 10;
      }
    }
  }

  .dd {
    position: relative;
    width: 250px;
    &.object {
      width: 250px !important;
      margin-top: 0px !important;
      font-size: 12px;
      font-weight: 500;
      // .dd-button {
      //   height: 27px !important;
      // }
    }
    .dd-button {
      @include ui-dropdown-button();
      border-radius: 0px 5px 5px 0px;
    }
    & ::v-deep .vs__dropdown-toggle {
      border: 1px solid $inputCaption;
      box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }
    &:not(:first-child) {
      margin-top: 10px;
    }
  }

}

.page-header__blockcode {
  @include white-button;
  width: 210px;
  margin-left: 30px;
}

.page-header__newcode {
  @include purple-button;
  width: 210px;
}

// .buttons-panel {
//   span {
//     color: $red;
//   }
// }

</style>
