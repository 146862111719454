import {hasContent} from "@/tools/dataTools";

export const PrizeGameItem = {
    // UUID
    id: '',
    // int
    score: 1,
    // int
    count: 1,
    // int
    minimalScoreToPlay: 0
};

export const NewPrizeGameItem = () => {
    let item = JSON.parse(JSON.stringify(PrizeGameItem))

    return item
};

export const PrizeGameItemFromBody = (data) => {
    let object = NewPrizeGameItem()

    object.id = data.id;
    object.score = data.score;
    object.count = data.count;
    object.minimalScoreToPlay = data.minimalScoreToPlay;

    return object;
};


export const PrizeGameItemRequiredVerify = (game) => {
    if(hasContent(game)) {
        return (
            hasContent(game.id) &&
            hasContent(game.score) && game.score > 0 &&
            hasContent(game.count) && game.count > 0 &&
            hasContent(game.minimalScoreToPlay) && game.minimalScoreToPlay >= 0

        )
    }

    return false;
};

// export module
export default {
    PrizeGameItem,
    NewPrizeGameItem,
    PrizeGameItemFromBody,

    PrizeGameItemRequiredVerify,
};
